export function getCibles (state) {
  return state.cibles
}

export function getMapCibles (state) {
  return state.mapCibles
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrentCible (state) {
  return state.currentCible
}
