<template>
  <div class="wrapper">
    <div class="row full-width shadow-up-16 content">
      <div class="col col-2 text-center" v-bind:class="`bg-${color}-light-5`">
        <div class="column fit justify-center">
          <h2 class="title section-title text-h2">{{sectionTitle}}</h2>
        </div>
      </div>

      <div class="col" style="min-height: 140px">
        <div class="column fit justify-between">
          <div class="col q-px-md q-py-none">
            <q-breadcrumbs class="q-pt-lg" color="agora">
              <q-breadcrumbs-el v-for="crumb in breadcrumbs" v-bind="crumb" :key="crumb.label" />
            </q-breadcrumbs>

            <h2 class="title q-ma-none">
              <sub v-if="parentRoute"><router-link :to="parentRoute">{{parentRoute.label}}</router-link></sub><br>
              <span v-if="typeof pageTitle === 'string'">{{ pageTitle }}</span>
              <div v-else-if="pageTitle" class="row items-end">
                {{ pageTitle.label }}
                <q-icon v-if="pageTitle.suffixIcon" class="q-ml-xs col col-shrink" :size="pageTitle.suffixIcon.size" :color="pageTitle.suffixIcon.color" :name="pageTitle.suffixIcon.name" />
              </div>
            </h2>
          </div>

          <div class="col col-auto items-end row">
            <q-tabs v-model="activeTab"
              align="left" no-caps dense narrow-indicator
              :class="`col text-${color}`" active-color="grey-9" :indicator-color="color">
              <q-route-tab v-for="tab in tabs" v-bind:key="tab.name" v-bind="tab" class="text-light" />
            </q-tabs>

            <div class="col col-auto q-pr-md q-py-sm" v-if="isListPage && listModelToExport.includes(model)">
              <q-btn :color="color" size="sm" outline @click.native="exportModelList(model)">{{$t('cibles.export')}}</q-btn>
            </div>

            <div class="col col-auto q-pr-md q-py-sm" v-if="isListPage && model != null && model !== 'entity'">
              <q-btn :color="color" size="sm" unelevated :to="{ name: `${model}-create` }">
                {{$t(`${($pluralize(model))}.add_${model}`)}}
              </q-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="background fit" :style="{ backgroundImage: `url('/statics/header-background.png')` }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import download from 'downloadjs'

export default {
  name: 'PageHeader',
  data () {
    return {
      activeTab: 0,
      listModelToExport: [
        'cible',
        'entity',
        'task'
      ]
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      model: 'pages/getModel',
      sectionTitle: 'pages/getSectionTitle',
      pageTitle: 'pages/getPageTitle',
      parentRoute: 'pages/getParentRoute',
      tabs: 'pages/getTabs',
      breadcrumbs: 'pages/getBreadcrumbs'
    }),

    isListPage () {
      return new RegExp('-list').test(this.$route.name)
    }
  },

  methods: {
    exportModelList (model) {
      let url = ''
      if (model === 'cible') url = '/api/groupe-cibles/cibles/export'
      else if (model === 'entity') url = '/api/entities/export'
      else if (model === 'task') url = '/api/taches/export'
      else return

      this.$axios.get(url, {
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'text/csv' }
      }).then(response => {
        let fileName = ''
        if (model === 'cible') fileName = 'cibles.csv'
        else if (model === 'entity') fileName = `export_reseau-${this.$formatDate(new Date())}.csv`
        else if (model === 'task') fileName = `taches_agora.csv`

        download(response.data, fileName)
      }).catch(this.$notifyError)
    }
  }
}
</script>

<style lang="stylus">
.wrapper
  position: relative

.content
  position: relative
  z-index: 100

.background
  position: absolute
  top: 0
  left: 0
  z-index: -100

.title, .section-title
  font-size: 1.5rem
  font-weight: 600

.title
  color: $grey-9
  line-height: 1.6rem

.q-breadcrumbs {
  font-size: 0.8rem
  text-transform: uppercase
}
</style>
text-transformdashboard-per
