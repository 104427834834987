export function setCibles (state, cibles) {
  state.cibles = cibles
}

export function setMapCibles (state, mapCibles) {
  state.mapCibles = mapCibles
}

export function setFormFields (state, formFields) {
  state.formFields = formFields
}

export function setFilters (state, filters) {
  state.filters = filters.map(filter => ({
    ...filter, value: filter.value ? filter.value : null
  }))
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setCurrentCible (state, cible) {
  state.currentCible = cible
}
