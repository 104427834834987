import axios from 'axios'

export function login ({ commit }, { email, password }) {
  return axios.post('/api/auth/login', { email, password })
    .then(response => response.data)
    .then(result => result.access_token)
    .then(token => {
      commit('setAccessToken', token)
    })
}

export function logout ({ commit }) {
  return axios.get('/api/auth/logout')
    .then(token => {
      commit('setAccessToken', null)
      commit('setCurrentUser', null)
    })
}

export function fetchUser ({ commit, getters }) {
  return axios.get('/api/auth/user')
    .then(response => response.data)
    .then(result => commit('setCurrentUser', result))
}

export function recoverPassword ({ commit, getters }) {
  return axios.post('/api/auth/password/reset')
    .then(response => response.data)
}
