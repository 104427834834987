export function getTasks (state) {
  return state.tasks
}

export function getTasksFor (state) {
  return (modelType, modelId) => { return state.tasks }
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrentTask (state) {
  return state.currentTask
}
