import regions from './regions'

const statuts = {
  PLANIFIE: 'Planifié',
  CLOTURE: 'Cloturé'
}

export default {
  instances: 'Instances',
  instance: 'Instance',
  instances_list: 'Comités de Région',
  add_instance: 'Créer une instance',
  edit_instance: 'Modifier l\'instance',
  delete_instance: 'Supprimer cette instance',
  delete_instance_confirm: 'Êtes-vous sûr de vouloir supprimer cette instance ?',
  cloturer_instance: 'Cloturer',
  cloturer_instance_confirm: 'Êtes-vous sûr de vouloir clôturer cette instance ? Si des sujets n\'ont pas été traités, il repasseront automatiquement au statut "À affecter" et devront être traités lors d\'une nouvelle instance.',
  instance_cloture: 'Instance cloturé',
  download_pdf: 'Télécharger le PDF',
  fields: {
    label: { label: 'Titre' },
    description: { label: 'Description' },
    date_instance: { label: 'Date' },
    statut: { label: 'État', options: statuts },
    region_id: { label: 'Région', options: regions },
    sujets_count: { label: 'Nbr de sujets' }
  },
  filters: {
    region_id: { label: 'Région', options: regions },
    date_instance: { label: 'Date' }
  },
  save_success: 'Instance sauvegardé',
  delete_success: 'Instance supprimé'
}
