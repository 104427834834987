import axios from 'axios'

export function fetchSujets ({ commit, getters }, params = {}) {
  const { statut } = params
  const sujets_url = statut !== 'A_PLANIFIER' ? '/api/comite/info/sujets' : '/api/comite/info/sujets-a-planifier'

  return axios.get(sujets_url, { params })
    .then(response => response.data)
    .then(result => {
      if (result.data) {
        commit('setSujets', result.data)
        commit('setPagination', Object.assign({}, getters['getPagination'], {
          page: result.current_page,
          rowsPerPage: result.per_page,
          rowsNumber: result.total
        }))
      } else {
        commit('setSujets', result)
      }
    })
}

export function fetchFormFields ({ commit }, params = {}) {
  params.model = 'sujet'
  return axios.get('/api/config/forms', { params })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function fetchFilters ({ commit }, params = {}) {
  params['model'] = 'sujet'

  return axios.get('/api/config/filters', { params })
    .then(response => response.data)
    .then(result => { commit('setFilters', result) })
}

export function getSujet ({ commit }, id) {
  return axios.get(`/api/comite/sujets/${id}`)
    .then(response => response.data)
    .then(evenement => { commit('setCurrentSujet', evenement) })
}

export function saveSujet ({ commit }, params = {}) {
  const { id } = params

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/comite/sujets/${id}` : '/api/comite/sujets/'
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function deleteSujet ({ commit }, id) {
  return axios.delete(`/api/comite/sujets/${id}`)
    .then(response => response.data)
}

export function deleteLink ({ commit }, id) {
  return axios.delete(`/api/liens-reseau/${id}`)
    .then(response => response.data)
}
