export function getMainMenuItems (state) {
  return state.mainMenuItems
}

export function getCounts (state) {
  return state.counts
}

export function getRowsPerPageOptions (state) {
  return state.rowsPerPageOptions
}
