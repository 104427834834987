export function getAccessToken (state) {
  return state.accessToken
}

export function isAuth (state) {
  return state.accessToken !== null
}

export function isAdmin (state) {
  const user = state.currentUser
  return user && user.roles.length && user.roles[0].name === 'admin'
}

export function isRPPR (state) {
  const user = state.currentUser
  return user && user.roles.length && user.roles[0].name === 'RPPR'
}

export function getCurrentUser (state) {
  return state.currentUser
}
