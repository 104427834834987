import axios from 'axios'

export function fetchComments ({ commit, getters }, params = {}) {
  delete params.rowsNumber

  return axios.get('/api/commentaires', { params })
    .then(response => response.data)
    .then(result => {
      if (result.data) {
        commit('setComments', result.data)
        commit('setPagination', Object.assign({}, getters['getPagination'], {
          page: result.current_page,
          rowsPerPage: result.per_page,
          rowsNumber: result.total
        }))
      } else {
        commit('setComments', result)
      }
    })
}

export function fetchFormFields ({ commit }) {
  return axios.get('/api/config/forms', { params: { model: 'commentaire' } })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function saveComment ({ commit }, params = {}) {
  const { id } = params

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/commentaires/${id}` : '/api/commentaires/'
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function deleteComment ({ commit }, id) {
  return axios.delete(`/api/commentaires/${id}`)
    .then(response => response.data)
}
