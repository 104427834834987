import axios from 'axios'

export function fetchTasks ({ commit, getters }, params = {}) {
  delete params.rowsNumber

  return axios.get('/api/taches', { params })
    .then(response => response.data)
    .then(result => {
      if (result.data) {
        commit('setTasks', result.data)
        commit('setPagination', Object.assign({}, getters['getPagination'], {
          page: result.current_page,
          rowsPerPage: result.per_page,
          rowsNumber: result.total
        }))
      } else {
        commit('setTasks', result)
      }
    })
}

export function fetchFormFields ({ commit }, params) {
  return axios.get('/api/config/forms', { params: { ...params, model: 'tache' } })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function fetchFilters ({ commit }) {
  return axios.get('/api/config/filters', { params: { model: 'tache' } })
    .then(response => response.data)
    .then(result => { commit('setFilters', result) })
}

export function getTask ({ commit }, id) {
  return axios.get(`/api/taches/${id}`)
    .then(response => response.data)
    .then(task => { commit('setCurrentTask', task); return task })
}

export function saveTask ({ commit }, params = {}) {
  const { id } = params

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/taches/${id}` : '/api/taches/'
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function deleteTask ({ commit }, id) {
  return axios.delete(`/api/taches/${id}`)
    .then(response => response.data)
}

export function fetchTaskComments ({ commit }, params = {}) {
  const { id } = params
  params.model_id = params.id
  delete params.id

  return axios.get(`/api/taches/${id}/commentaires`, { params })
    .then(response => response.data)
}
