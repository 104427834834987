/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import 'quasar/dist/quasar.ie.polyfills.js'



import '@quasar/extras/roboto-font/roboto-font.css'

import '@quasar/extras/material-icons/material-icons.css'




// We load Quasar stylus files
import 'quasar/dist/quasar.styl'




import 'src/css/app.styl'


import Vue from 'vue'
import createApp from './app.js'




import qboot_Bootcomponents from 'boot/components'

import qboot_Bootpages from 'boot/pages'

import qboot_Booti18n from 'boot/i18n'

import qboot_Bootaxios from 'boot/axios'

import qboot_Bootauth from 'boot/auth'

import qboot_Bootmapbox from 'boot/mapbox'

import qboot_Boothelpers from 'boot/helpers'

import qboot_Bootpluralize from 'boot/pluralize'

import qboot_Bootnotify from 'boot/notify'



import { addPreFetchHooks } from './client-prefetch.js'










const { app, store, router } = createApp()



async function start () {
  
  const bootFiles = [qboot_Bootcomponents,qboot_Bootpages,qboot_Booti18n,qboot_Bootaxios,qboot_Bootauth,qboot_Bootmapbox,qboot_Boothelpers,qboot_Bootpluralize,qboot_Bootnotify]
  for (let i = 0; i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null
      })
    }
    catch (err) {
      if (err && err.url) {
        window.location.href = err.url
        return
      }

      console.error('[Quasar] boot error:', err)
      return
    }
  }
  

  

    
    addPreFetchHooks(router, store)
    

    

      new Vue(app)

    

  

}

start()
