export function setAccessToken (state, token) {
  if (token) {
    localStorage.setItem('access_token', token)
  } else {
    localStorage.removeItem('access_token')
  }
  state.accessToken = token
}

export function setCurrentUser (state, user) {
  state.currentUser = user
}
