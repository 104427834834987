export default {
  ADMINISTRATEUR: 'Administrateur',
  UTILISATEUR_SIEGE: 'Utilisateur siège',
  DCR: 'DCR',
  RPPR: 'RPPR',
  GESTIONNAIRE_REGION: 'Gestionnaire région',
  IR: 'IR',
  ICRA: 'ICRA',
  AUTRE: 'Autres',
  DIRECTION: 'Direction'
}
