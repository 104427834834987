export function setTasks (state, tasks) {
  state.tasks = tasks
}

export function setFormFields (state, formFields) {
  state.formFields = formFields
}

export function setFilters (state, filters) {
  state.filters = filters.map(filter => ({
    ...filter, value: filter.value ? filter.value : null
  }))
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setCurrentTask (state, task) {
  state.currentTask = task
}

export function setComments (state, comments) {
  state.comments = comments
}
