export function getFromName (state) {
  return state.fromName
}

export function getFromParams (state) {
  return state.fromParams
}

export function getSectionTitle (state) {
  return state.sectionTitle
}

export function getPageTitle (state) {
  return state.pageTitle
}

export function getParentRoute (state) {
  return state.parentRoute
}

export function getColor (state) {
  return state.color
}

export function getModel (state) {
  return state.model
}

export function getTabs (state) {
  return state.tabs
}

export function getBreadcrumbs (state) {
  return state.breadcrumbs
}
