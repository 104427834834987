import axios from 'axios'

export function fetchFormFields ({ commit }, params) {
  return axios.get('/api/config/forms', { params: { ...params, model: 'cible' } })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result); return result })
}

export function fetchFilters ({ commit }) {
  return axios.get('/api/config/filters', { params: { model: 'cible' } })
    .then(response => response.data)
    .then(result => { commit('setFilters', result) })
}

export function getSousCible ({ commit }, id) {
  return axios.get(`/api/cibles/${id}`)
    .then(response => response.data)
    .then(cible => { commit('setCurrentSousCible', cible) })
}

export function saveSousCible ({ commit }, params) {
  const { id, date_validation, ...data } = params
  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/cibles/${id}` : '/api/cibles/'

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  })
    .then(response => response.data)
}

export function deleteSousCible ({ commit }, id) {
  return axios.delete(`/api/cibles/${id}`)
    .then(response => response.data)
}

export function saveLinkedObject (_, params) {
  const { type, id } = params

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/${type}/${id}` : `/api/${type}/`

  delete params.type
  delete params.id

  const data = params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  })
    .then(response => response.data)
}
