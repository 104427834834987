import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

export default async ({ app, router, store, Vue }) => {
  const redirectIfTokenNotRefreshable = error => {
    if (error.response.data.message === 'TOKEN_EXPIRED_NOT_REFRESHABLE') {
      store.commit('auth/setAccessToken', null)
      router.push({ name: 'login' })
    }
    return Promise.resolve()
  }

  createAuthRefreshInterceptor(axios, failedRequest => {
    redirectIfTokenNotRefreshable(failedRequest)
    return axios.get('/api/auth/refresh').then(response => {
      store.commit('auth/setAccessToken', response.data.access_token)
      return Promise.resolve()
    }).catch(error => {
      redirectIfTokenNotRefreshable(error)
    })
  })

  axios.interceptors.request.use(request => {
    request.headers['Authorization'] = `Bearer ${store.getters['auth/getAccessToken']}`
    return request
  })
  // axios.interceptors.response.use(response => {
  //   return Promise.resolve(response)
  // }, error => {
  //   const message = error.response.data.message
  //   if (message === 'TOKEN_EXPIRED') {
  //     axios.get('/api/auth/refresh')
  //       .then(response => response.data)
  //       .then(result => {
  //         // console.log(result)
  //         return result.access_token
  //       })
  //       .then(token => {
  //         store.commit('auth/setAccessToken', token)
  //
  //         const request = error.config
  //         // console.log(request)
  //         request.headers['Authorization'] = `Bearer ${token}`
  //         return axios.request(request)
  //       })
  //   } else if (message === 'TOKEN_EXPIRED_NOT_REFRESHABLE' || message === 'TOKEN_INVALID') {
  //   // } else {
  //     store.commit('auth/setAccessToken', null)
  //     store.commit('auth/setCurrentUser', null)
  //     router.push('/login')
  //   }
  //
  //   return Promise.reject(error.response.data)
  // })

  // console.log(process.env.API_URL)
  if (process.env.API_URL) { axios.defaults.baseURL = process.env.API_URL }

  Vue.prototype.$axios = axios
}
