<template>
  <q-page class="flex flex-top">
    <div class="column full-width">
      <PageHeader />

      <div class="col">
        <div class="row fit q-py-lg">
          <div class="col col-2">
            <slot name="left-column" />
          </div>
          <div class="col col-lg-9 q-px-md">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { PageHeader } from '../pages'

export default {
  name: 'BasePage',
  components: { PageHeader }
}
</script>

<style lang="stylus" scoped>
</style>
