export function setProcesses (state, processes) {
  state.processes = processes
}

export function setFormFields (state, formFields) {
  state.formFields = formFields
}

export function setFilters (state, filters) {
  state.filters = filters.map(filter => ({
    ...filter, value: filter.value ? filter.value : null
  }))
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setCurrentProcess (state, Process) {
  state.currentProcess = Process
}
