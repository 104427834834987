<template>
  <q-input class="q-pa-none fit" :dense="dense" stack-label
    :label="$t(label)" :color="color" no-error-icon
    :value="$formatDate(value)" ref="input"
    :rules="rulesComputed" :readonly="readonly" :placeholder="!readonly ? 'Choisir...' : null"
    @blur="onChange" @keydown.enter.stop="onChange" @click.self="$refs['qDateProxy'].show()">
    <template v-slot:append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy ref="qDateProxy">
          <q-date :color="color" today-btn
            :value="$formatDate(value, 'YYYY/MM/DD')" :title="title"
            @input="onSelectDate" />
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
import { BaseInput } from '../../mixins'

export default {
  name: 'BaseInputDate',
  mixins: [BaseInput],
  props: {
    dense: { Boolean, default: false }
  },

  computed: {
    title () {
      return this.$formatDate(this.value, 'dddd DD MMM')
    },

    rulesComputed () {
      return [this.baseRules, this.isValidDate]
    }
  },

  watch: {
    value (newVal, oldVal) {
      // console.log(newVal)
    }
  },

  methods: {
    isValidDate (v) {
      if (!this.required && (!v || v === '')) return true
      return /^-?[0-3]\d\/[0-1]\d\/[\d]+$/.test(v) || this.$t('form.invalid_date')
    },

    onChange (event) {
      const val = event.target.value

      if (this.isValidDate(val)) {
        if (!val || '') {
          this.$emit('input', null)
        } else {
          const [day, month, year] = val.split('/')
          const newDate = new Date(year, month - 1, day)
          this.$emit('input', this.$formatDate(newDate, 'YYYY-MM-DD'))
        }
      } else {
        this.$emit('input', null)
      }
    },

    onSelectDate (value) {
      const [year, month, day] = value.split('/')
      const newDate = new Date(year, month - 1, day)
      this.$emit('input', this.$formatDate(newDate, 'YYYY-MM-DD'))
      this.$refs['qDateProxy'].hide()
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
