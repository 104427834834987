import regions from './regions'

export default {
  entities: 'Entités',
  entity: 'Entité',
  entities_list: 'Liste des entités',
  label: 'Nom de l\'entité',
  segment: 'Segmentation agence',
  postes: 'Nombre de postes',
  nbrPostes: 'Nbr postes',
  is_red: 'Agence rouge',
  agents: 'Agents',
  region: 'Région',
  clubs: 'Clubs',
  metrics: {
    label: 'Chiffres clés',
    comm_totale: 'Comm totale',
    sp_irt: 'SP/IRT',
    ca_iard: 'CA IARD',
    tauxEntreeEtSortie: 'Taux d\'entrée et de sortie',
    montant_total_prime_IA: 'Montant total prime AN en IA',
    solde_production_IA: 'Solde Production IA'
  },
  peda: {
    label: 'Dispositif PEDA',
    pro: 'Professionnels',
    entr_agri: 'Ent/Agricole/Construction',
    prev: 'Prévoyance/Santé'
  },
  evenement: {
    description: 'Description',
    compteRendu: 'Compte-rendu'
  },
  oscarr: 'Données OSCARR',
  filters: {
    ageAgent: { label: 'Age de l\'agent' },
    commissions: { label: 'Commissions' },
    nbAgent: { label: 'Nombre d\'agents' },
    SP: { label: 'S/P IRT' },
    region_id: { label: 'Région', options: regions },
    cible: { label: 'Avec cible' },
    is_red: { label: 'Agence rouge' },
    mdt_gest: { label: 'Mandat de gestion' },
    segment_id: { label: 'Segmentation agence' },
    postes: { label: 'Nombre de postes' },
    pdv: { label: 'Nombre PDV' },
    NB_ETP: { label: 'Nombre ETP Collab' },
    date_nomination_before: { label: 'Date de nomination / Avant le' },
    date_nomination_after: { label: 'Date de nomination / Après le' }
  },
  fields: {
    admin_note: { label: 'Commentaire' },
    is_red: { label: 'Agence rouge' },
    segment: { label: 'Segmentation agence' },
    nom_entite_temporaire: { label: 'Nom de l\'entité temporaire' },
    postes: { label: 'Nbr postes' }
  },
  add_cible: 'Ajouter une cible',
  save_success: 'Entité sauvegardée',
  go_to_vizagence: 'Voir sur Vizagence',
  manageSegment: 'Gérer les segments',
  newSegment: 'Nouveau segment',
  enterNewSegment: 'Entrez le nouveau segment',
  manageTheSegments: 'Gestion des segments',
  segment_added: 'Segment ajouté avec succès',
  segment_unlinked: 'Segment detaché avec succès',
  segments_updated: 'Segments modifiés avec succès'
}
