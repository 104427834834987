<template>
  <q-input :value="value" v-on="$listeners" ref="input"
    :mask="mask"
    :hint="hint"
    :label="$t(label)" :color="color" stack-label no-error-icon :dense="dense"
    :rules="rulesComputed" :type="type" :placeholder="placeholderComputed" :suffix="suffix"
    :readonly="readonly" :password="type === 'password'" :min="type === 'number' && 0"
    :input-class="inputClass" :outlined="isTextArea" rows="7" />
</template>

<script>
import { BaseInput } from '../../mixins'

export default {
  name: 'BaseInputText',
  mixins: [BaseInput],

  props: {
    suffix: { type: String, default: null },
    placeholder: { type: String, default: null },
    mask: { type: Function },
    hint: { type: String }
  },

  computed: {
    placeholderComputed () {
      return this.placeholder ? this.placeholder : this.$t(this.label)
    },
    rulesComputed () {
      let _rules = this.baseRules.slice()
      if (this.type === 'email') _rules.push(val => val.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) || this.$t('form.invalid_email'))
      return _rules
    },

    inputClass () {
      return this.id === 'label' ? 'text-h5' : ''
    },

    isTextArea () {
      return this.type === 'textarea'
    }
  },

  methods: {
    validate () {
      return true
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
