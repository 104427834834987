const statuts = {
  BROUILLON: 'Brouillon',
  RETENU: 'Retenu',
  SHORTLIST: 'Shortlist',
  CANDIDAT: 'Candidat',
  REFUSE: 'Refusé',
  ABANDONNE: 'Abandonné'
}
const avisRhOption = {
  FAVORABLE: 'Favorable',
  ASSEZ_FAVORABLE: 'Assez favorable',
  RESERVE: 'Réservé',
  DEFAVORABLE: 'Défavorable'
}
export default {
  fields: {
    statut: { label: 'État', options: statuts },
    nom: { label: 'Nom Prénom' },
    avis_rh: { label: 'Avis RH', options: avisRhOption },
    date_rdv_rh: { label: 'Date RDV RH' },
    validation_dcr: { label: 'Validation DCR' },
    date_validation_dcr: { label: 'Date validation DCR' },
    validation_dir_comm: { label: 'Validation Dir Comm' },
    date_validation_dir_comm: { label: 'Date RDV Dir Comm' },
    signature_lettre_agrement_impossible: { label: 'Signature lettre d\'agrément impossible' },
    date_signature_lettre_agrement: { label: 'Date signature lettre d\'agrément' },
    session_stage_id: { label: 'Stage souhaité' },
    date_stage: { label: 'Date effective du stage' },
    date_previsionnelle_nomination: { label: 'Date prévisionnelle de la nomination' }
  }
}
