import { render, staticRenderFns } from "./base-input-boolean.vue?vue&type=template&id=6712c322&scoped=true&"
import script from "./base-input-boolean.vue?vue&type=script&lang=js&"
export * from "./base-input-boolean.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6712c322",
  null
  
)

export default component.exports