export default {
  formFields: null,
  filters: null,
  pagination: {
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 0
  },
  cibles: [],
  mapCibles: [],
  currentCible: null
}
