import axios from 'axios'

export function fetchInstances ({ commit, getters }, params = {}) {
  const { compte_rendu } = params
  const instances_url = !compte_rendu ? '/api/comite/info' : '/api/comite/info/compte-rendus'

  return axios.get(instances_url, { params })
    .then(response => response.data)
    .then(result => {
      if (result.data) {
        commit('setInstances', result.data)
        commit('setPagination', Object.assign({}, getters['getPagination'], {
          page: result.current_page,
          rowsPerPage: result.per_page,
          rowsNumber: result.total
        }))
      } else {
        commit('setInstances', result)
      }
    })
}

export function fetchFormFields ({ commit }, params = {}) {
  console.log(params)
  params['model'] = 'instance'
  return axios.get('/api/config/forms', { params })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function fetchFilters ({ commit }) {
  return axios.get('/api/config/filters', { params: { model: 'instance' } })
    .then(response => response.data)
    .then(result => { commit('setFilters', result) })
}

export function getInstance ({ commit }, id) {
  return axios.get(`/api/comite/instances/${id}`)
    .then(response => response.data)
    .then(evenement => { commit('setCurrentInstance', evenement) })
}

export function saveInstance ({ commit }, params = {}) {
  const { id } = params

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/comite/instances/${id}` : '/api/comite/instances/'
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function deleteInstance ({ commit }, id) {
  return axios.delete(`/api/comite/instances/${id}`)
    .then(response => response.data)
}
