export function getDashboardData (state) {
  const { comptage_reseau, comptage,
    cibles_EN_COURS, cibles_TERMINE, cibles_ABANDONNE,
    sous_cibles_EN_COURS, sous_cibles_TERMINE, sous_cibles_ABANDONNE
  } = state

  return {
    comptage_reseau,
    cibles_EN_COURS,
    cibles_TERMINE,
    cibles_ABANDONNE,
    sous_cibles_EN_COURS,
    sous_cibles_TERMINE,
    sous_cibles_ABANDONNE,
    comptage
  }
}

export function getFilters (state) {
  return state.filters
}
