export function setCandidats (state, candidats) {
  state.candidats = candidats
}

export function setFormFields (state, formFields) {
  state.formFields = formFields
}

export function setFilters (state, filters) {
  state.filters = filters.map(filter => ({
    ...filter, value: filter.value ? filter.value : null
  }))
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setCurrentCandidat (state, candidat) {
  state.currentCandidat = candidat
}

export function setSessionStage (state, sessionStage) {
  state.sessionStage = sessionStage
}
