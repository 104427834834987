import axios from 'axios'

export function fetchPtfsConfies ({ commit, getters }, params = {}) {
  delete params.rowsNumber

  return axios.get('/api/processAgences/portefeuilleConfie', { params })
    .then(response => response.data)
    .then(result => {
      if (result.data) {
        commit('setPtfsConfies', result.data)
        commit('setPagination', Object.assign({}, getters['getPagination'], {
          page: result.current_page,
          rowsPerPage: result.per_page,
          rowsNumber: result.total
        }))
      }
    })
}

export function fetchPtfConfieFormFields ({ commit }) {
  return axios.get('/api/config/forms', { params: { model: 'portefeuilleConfie' } })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function fetchFilters ({ commit }) {
  return axios.get('/api/config/filters', { params: { model: 'portefeuilleConfie' } })
    .then(response => response.data)
    .then(result => { commit('setFilters', result) })
}

export function fetchCurrentPtfConfie ({ commit }, id) {
  return axios.get(`/api/processAgences/portefeuilleConfie/${id}`)
    .then(response => response.data)
    .then(ptfConfie => { commit('setCurrentPtfConfie', ptfConfie) })
}

export function savePtfConfie ({ commit }, params = {}) {
  const { id } = params

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/processAgences/portefeuilleConfie/${id}` : `/api/processAgences/portefeuilleConfie/`
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function deletePtfConfie ({ commit }, id) {
  return axios.delete(`/api/processAgences/portefeuilleConfie/${id}`)
    .then(response => response.data)
}
