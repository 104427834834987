import roles from './roles'
import regions from './regions'

export default {
  users: 'Utilisateurs',
  user: 'Utilisateur',
  users_list: 'Liste des utilisateurs',
  management: 'Utilisateurs',
  add_user: 'Ajouter un utilisateur',
  edit_user: 'Modifier l\'utilisateur',
  delete_user: 'Supprimer',
  delete_user_confirm: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
  save_success: 'Utilisateur sauvegardé',
  delete_success: 'Utilisateur supprimé',
  filters: {
    role: { label: 'Rôle', options: roles },
    region_id: { label: 'Région', options: regions }
  },
  fields: {
    nom: { label: 'Nom' },
    prenom: { label: 'Prénom' },
    portefeuille: { label: 'Portefeuille' },
    entite: { label: 'Entité' },
    email: { label: 'E-mail' },
    password: { label: 'Mot de passe' },
    display_name: { label: 'Nom' },
    status: { label: 'Statut' },
    role: { label: 'Rôle', options: roles },
    region_id: { label: 'Région', options: regions }
  }
}
