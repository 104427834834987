import axios from 'axios'
import {
  getModelTypeFromPhpClass,
  getLabelByModelType,
  getCaptionByModelType,
  getLinkedObjectsFromModel
} from '../../boot/helpers'

const getRef = (type, model) => {
  if (!model) return null

  if (type === 'agent') {
    return { id: model['entities'][0]['id'], type: 'entity' }
  } else if (type === 'wallet') {
    return { id: model['entity']['id'], type: 'entity' }
  } else if (type === 'entity') {
    return { id: model['id'], type: 'entity' }
  } else if (type === 'cible') {
    return { id: model['id'], type }
  } else if (type === 'sousCible') {
    return { id: model['groupe_cible'], type: 'cible' }
  } else if (type === 'evenement') {
    const { type: linkedObjType, obj: linkedObj } = getLinkedObjectsFromModel(model).pop()
    return { id: linkedObj.id, type: linkedObjType }
  } else if (type === 'task') {
    return { id: model['id'], type: 'task' }
  }
}

const sanitize = data => {
  return data.map(fav => {
    const id = fav.model_id
    const type = getModelTypeFromPhpClass(fav.model_type)
    const label = getLabelByModelType(type, fav.model)
    const caption = getCaptionByModelType(type, fav.model)
    const ref = getRef(type, fav.model)

    return { id, type, label, caption, ref }
  }).reduce((acc, val) => {
    if (!val.ref) return acc
    const type = val.type
    const fav = val

    return acc[type] ? { ...acc, [type]: acc[type].concat(fav) } : { ...acc, [type]: [fav] }
  }, {})
}

export function fetchFavorites ({ commit, getters }, params = {}) {
  params.rowsPerPage = 0

  return axios.get('/api/favoris', { params })
    .then(response => response.data)
    .then(results => {
      commit('setFavorites', sanitize(results))
    })
}

export function saveFavorite ({ dispatch }, params = {}) {
  return axios.post('/api/favoris/', params)
    .then(() => { dispatch('fetchFavorites') })
}

export function deleteFavorite ({ dispatch }, params) {
  return axios.delete('/api/favoris', { params })
    .then(() => { dispatch('fetchFavorites') })
}
