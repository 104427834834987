import axios from 'axios'

export function fetchCandidats ({ commit, getters }, params = {}) {
  delete params.rowsNumber

  return axios.get('/api/processAgences/candidat', { params })
    .then(response => response.data)
    .then(result => {
      if (result.data) {
        commit('setCandidat', result.data)
        commit('setPagination', Object.assign({}, getters['getPagination'], {
          page: result.current_page,
          rowsPerPage: result.per_page,
          rowsNumber: result.total
        }))
      }
    })
}

export function fetchCandidatFormFields ({ commit }) {
  return axios.get('/api/config/forms', { params: { model: 'candidat' } })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function fetchFilters ({ commit }) {
  return axios.get('/api/config/filters', { params: { model: 'candidat' } })
    .then(response => response.data)
    .then(result => { commit('setFilters', result) })
}

export function fetchCurrentCandidat ({ commit }, id) {
  return axios.get(`/api/processAgences/candidat/${id}`)
    .then(response => response.data)
    .then(candidat => { commit('setCurrentCandidat', candidat) })
}

export function fetchSessionStage ({ commit }, id) {
  return axios.get(`/api/processAgences/sessionStage`)
    .then(response => response.data)
    .then(sessionStage => { commit('setSessionStage', sessionStage.data ? sessionStage.data : sessionStage) })
}

export function saveCandidat ({ commit }, params = {}) {
  const { id } = params

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/processAgences/candidat/${id}` : '/api/processAgences/candidat/'
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function deleteCandidat ({ commit }, id) {
  return axios.delete(`/api/processAgences/candidat/${id}`)
    .then(response => response.data)
}
