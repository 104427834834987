<template>
  <div id="q-app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',

  meta () {
    return {
      titleTemplate: title => `${title} - AGORA`
    }
  }
}
</script>

<style>
</style>
