<template>
  <q-toggle :dense="dense"
    :value="value" @input="val => $emit('input', val)"
    :label="$t(label)" :color="color" ref="input" keep-color :left-label="leftLabel"
    :disable="readonly"
  />
</template>

<script>
import { BaseInput } from '../../mixins'

export default {
  name: 'BaseInputBoolean',
  mixins: [BaseInput],
  props: [ 'leftLabel' ],

  methods: {
    validate () {}
  }
}
</script>

<style lang="stylus" scoped>
</style>
