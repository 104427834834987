export default async ({ router, store }) => {
  const token = localStorage.getItem('access_token')
  if (token) store.commit('auth/setAccessToken', token)

  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.validate)) {
      const m = to.matched.find(m => m.meta.validate)
      const isAuth = store.getters['auth/isAuth']

      if (m.meta.validate.indexOf('auth') >= 0 && !isAuth) {
        return next({ path: '/login' })
      }

      if (m.meta.validate.indexOf('guest') >= 0 && isAuth) {
        return next({ path: '/' })
      }
    }

    return next()
  })
}
