import { date } from 'quasar'

export const getModelTypeFromPhpClass = className => {
  if (className === 'App\\Agent') return 'agent'
  else if (className === 'App\\Wallet') return 'wallet'
  else if (className === 'App\\Entity') return 'entity'
  else if (className === 'App\\GroupeCible') return 'cible'
  else if (className === 'App\\Cible') return 'sousCible'
  else if (className === 'App\\Evenement') return 'evenement'
  else if (className === 'App\\Tache') return 'task'
}

export const getLinkedObjectsFromModel = model => {
  let objs = []

  if (model.agents && model.agents.length > 0) model.agents.forEach(agent => objs.push({ type: 'agent', obj: { ...agent, label: agent.TITULAIRE } }))
  if (model.wallets && model.wallets.length > 0) model.wallets.forEach(wallet => objs.push({ type: 'wallet', obj: { ...wallet, label: wallet.NOMPTF } }))
  if (model.entities && model.entities.length > 0) model.entities.forEach(entity => objs.push({ type: 'entity', obj: entity }))

  return objs
}

export const getLabelByModelType = (type, model) => {
  if (!model) return null

  let label = model['label']

  if (type === 'agent') label = model['TITULAIRE']
  else if (type === 'wallet') label = model['NOMPTF']
  else if (type === 'cible') label = model['label']
  else if (type === 'sousCible') label = model['type']
  else if (type === 'evenement') label = model['label']
  else if (type === 'task') label = model['label']

  return label
}

export const getCaptionByModelType = (type, model) => {
  if (!model) return null

  let caption = ''

  if (type === 'agent') caption = `${model['entities'][0]['label']}`
  else if (type === 'wallet') caption = `${model['entity']['label']}`

  return caption
}

export default async ({ app, router, Vue }) => {
  Vue.mixin({
    methods: {
      $formatDate: (val, format = 'DD/MM/YYYY') => date.formatDate(val, format),

      $getIcon (type, value) {
        switch (type) {
          case 'statut':
            if (value === 'ABANDONNE') {
              return 'block'
            } else if (value === 'BROUILLON') {
              return 'turned_in_not'
            } else if (value === 'EN_COURS') {
              return 'rotate_right'
            } else if (value === 'TRAITE') {
              return 'check_circle'
            } else if (value === 'PROPOSE_RPPR') {
              return 'alarm_on'
            } else if (value === 'PROPOSE_SIEGE') {
              return 'alarm_on'
            } else if (value === 'TERMINE') {
              return 'check_circle_outline'
            }
            return ''

          case 'qualification':
            if (value === 4) {
              return 'icon-meteo_up'
            } else if (value === 3) {
              return 'icon-meteo_middle'
            } else if (value === 2) {
              return 'icon-meteo_down'
            } else if (value === 1) {
              return 'icon-meteo_dead'
            }
            return ''

          default:
            return ''
        }
      },

      $formatNumber: number => {
        if (Math.abs(number) >= 1000000) return (number / 1000000).toFixed(2) + 'M'
        if (Math.abs(number) >= 1000) return Math.round(number / 1000) + 'K'
        return number
      }
    }
  })
}
