export default {
  sectionTitle: null,
  pageTitle: null,
  parentRoute: null,
  breadcrumbs: null,
  color: 'primary',
  model: null,
  tabs: [],
  fromName: null,
  fromParams: null
}
