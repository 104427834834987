import statuts from './statuts'
import qualifications from './qualifications'

export default {
  tasks_list: 'Liste des tâches',
  tasks: 'Tâches',
  task: 'Tâche',
  no_tasks: 'Aucune tâche',
  fields: {
    model: { label: 'Objet lié' },
    label: { label: 'Titre de la tâche' },
    auteur: { label: 'Créateur' },
    destinataire: { label: 'Acteur' },
    description: { label: 'Description' },
    date_cible: { label: 'Date d\'écheance' },
    date_rappel: { label: 'Date de rappel' },
    statut: { label: 'État', options: statuts },
    qualification: { label: 'Météo', options: qualifications }
  },
  filters: {
    auteur_id: { label: 'Créateur' },
    destinataire_id: { label: 'Acteur' },
    date_start: { label: 'Après le' },
    date_end: { label: 'Avant le' }
  },
  add_task: 'Ajouter une tâche',
  edit_task: 'Modifier la tâche',
  create_task: 'Créer une tâche',
  delete_task: 'Supprimer',
  delete_task_confirm: 'Êtes-vous sûr de vouloir supprimer cette tâche ?',
  save_success: 'Tâche sauvegardée',
  delete_success: 'Tâche supprimée',
  remove_destinataire: 'Détacher l\'acteur',
  remove_linked_object: 'Détacher l\'objet lié',
  export: 'Exporter'
}
