const camelCase = require('voca/camel_case')
const titleCase = require('voca/title_case')

export default async ({ app, router, Vue }) => {
  const requireComponent = require.context('../components/base', true, /\.vue$/)

  requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = titleCase(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')))
    Vue.component(componentName, componentConfig.default || componentConfig)
  })
}
