<template>
  <q-select :value="valueFormated" @input="val => $emit('input', val)" ref="input"
    :label="$t(label)" :color="color" options-dense class="fit" no-error-icon stack-label
    :options="optionsFormated" :dense="dense"
    :rules="rules" :readonly="readonly" :multiple="multiple">

    <template v-slot:selected>
      <slot name="selected" :props="valueFormated">
        <q-item class="q-pa-none fit" dense>
          <q-item-section v-if="valueFormated">
            <q-item-label class="row justify-start" v-if="['statut'].indexOf(id) >= 0">
              <q-icon size="1rem" :name="$getIcon(id, valueFormated.value)" class="q-px-md" />
              <span>{{$te(valueFormated.label) ? $t(valueFormated.label) : valueFormated.label}}</span>
            </q-item-label>

            <q-item-label class="row justify-start" v-else-if="['qualification'].indexOf(id) >= 0">
              <q-icon size="1rem" :name="$getIcon(id, valueFormated.value)" class="q-px-md" />
            </q-item-label>

            <q-item-label v-else>{{$te(valueFormated.label) ? $t(valueFormated.label) : valueFormated.label}}</q-item-label>
          </q-item-section>

          <q-item-section v-else>{{placeholder}}</q-item-section>
        </q-item>
      </slot>
    </template>

    <template v-slot:option="scope">
      <q-item class="q-pa-none fit" dense v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section>
          <q-item-label class="row justify-start" v-if="['statut'].indexOf(id) >= 0">
            <q-icon size="1rem" :name="$getIcon(id, scope.opt.value)" class="q-px-md" />
            <span>{{$te(scope.opt.label) ? $t(scope.opt.label) : scope.opt.label}}</span>
          </q-item-label>

          <q-item-label class="row justify-start" v-else-if="['qualification'].indexOf(id) >= 0">
            <q-icon size="1rem" :name="$getIcon(id, scope.opt.value)" class="q-px-md" />
          </q-item-label>

          <q-item-label v-else class="q-pa-sm">{{$te(scope.opt.label) ? $t(scope.opt.label) : scope.opt.label}}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
import { BaseInput } from '../../mixins'

export default {
  name: 'BaseInputSelect',
  mixins: [BaseInput],
  props: {
    placeholder: { type: String, default: 'Choisir...' },
    multiple: { type: Boolean, default: false }
  },
  data () {
    return {

    }
  },
  computed: {
    valueFormated () {
      return this.value
        ? this.isAllOptionsLabelANumber()
          ? {
            label: this.getValueOptionLabel(this.value.label),
            value: this.value.value
          }
          : this.value
        : null
    },
    optionsFormated () {
      return this.isAllOptionsLabelANumber()
        ? this.options.map(option => ({
          label: this.getValueOptionLabel(option.label),
          value: option.value
        }))
        : this.options
    }
  },
  methods: {
    getValueOptionLabel (label) {
      return label.toString().split('.')[label.toString().split('.').length - 1]
    },
    isAllOptionsLabelANumber () {
      for (let i = 0; i < this.options.length; i++) {
        let option = this.options[i]

        if (isNaN(this.getValueOptionLabel(option.label))) return false
      }
      return true
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
