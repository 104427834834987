export default async ({ app, router, store, Vue }) => {
  router.beforeEach((to, from, next) => {
    store.commit('pages/setFromRoute', from)
    store.commit('pages/setColor', to.matched.reduce((acc, { meta: { color } }) => (color || acc), 'agora'))
    store.commit('pages/setSectionTitle', to.matched.reduce((acc, { meta: { section } }) => (app.i18n.t(section) || acc), ''))
    store.commit('pages/setModel', to.matched.reduce((acc, { meta: { model } }) => (model || acc), ''))

    const paths = to.matched.map(m => m.path)
    paths.shift()

    if (!from.matched.some(m => paths.indexOf(m.path) >= 0)) {
      store.commit('pages/setPageTitle', '')
      store.commit('pages/setParentRoute', null)
      store.commit('pages/setTabs', [])
    }

    next()
  })
}
