<template>
  <q-btn class="q-pa-md fit bg-white" icon="add_circle" outline
    :label="label" :to="to" :color="color" @click="$emit('click')" />
</template>

<script>
export default {
  name: 'BaseAddButton',
  props: {
    label: { type: String },
    color: { type: String },
    to: { type: Object }
  }
}
</script>

<style lang="stylus" scoped>
</style>
