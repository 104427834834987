export function setSectionTitle (state, sectionTitle) {
  state.sectionTitle = sectionTitle
}

export function setPageTitle (state, pageTitle) {
  state.pageTitle = pageTitle
}

export function setParentRoute (state, parentRoute) {
  state.parentRoute = parentRoute
}

export function setBreadcrumbs (state, breadcrumbs) {
  state.breadcrumbs = breadcrumbs
}

export function setColor (state, color) {
  state.color = color
}

export function setModel (state, model) {
  state.model = model
}

export function setTabs (state, tabs) {
  state.tabs = tabs
}

export function setFromRoute (state, { name, params }) {
  state.fromName = name
  state.fromParams = params
}
