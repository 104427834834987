export function getNotes (state) {
  return state.notes
}

export function getNotesFor (state) {
  return (modelType, modelId) => { return state.notes }
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getFilterValues (state) {
  return state.filterValues
}

export const getFilterValue = (state) => (filterId) => {
  return state.filterValues[filterId]
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrent (state) {
  return state.currentNote
}
