export default {
  ABANDONNE: 'Abandonné',
  BROUILLON: 'Brouillon',
  AFFECTE: 'Affecté',
  PROPOSE_RPPR: 'Proposé RPPR',
  EN_COURS: 'En cours',
  A_VALIDER: 'À valider',
  PROPOSE_SIEGE: 'Proposé Siège',
  TERMINE: 'Terminée'
}
