<template>
  <div class="search-box">
    <q-select v-model="modelType" ref="model" @input="searchQuery = null"
      label="Type" :color="color"
      options-dense stack-label map-options emit-value use-chips
      :options="options"
      v-if="model_type === undefined" />

    <q-select v-if="modelType"
      v-model="searchQuery"
      :label="label" class="fit"
      :color="color"
      use-input use-chips options-dense hide-dropdown-icon
      :options="searchResults" :option-label="label_field || 'label'"
      @filter="filterFn"
      @input="onResultSelect"
      transition-show="jump-up"
      transition-hide="jump-down"
    >
      <template v-slot:prepend>
        <q-icon name="search" size="16px" />
      </template>

      <template v-slot:selected-item="scope">
        <q-chip @remove="onResultSelect(null)" dense removable
          :color="color" text-color="white" size="sm">
          <div>{{scope.opt[label_field]}}</div>
        </q-chip>
      </template>

      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-grey">
            Aucun résultats
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script>
export default {
  name: 'SearchBox',
  props: {
    'model_type': { String },
    'label_field': { String },
    'label': { String },
    'color': { String },
    'params': { Array },
    'options': { Array,
      default: () => ([
        { value: 'entity', label: 'Entité' },
        { value: 'wallet', label: 'Portefeuille' },
        { value: 'agent', label: 'Agent' }
      ]) }
  },
  data () {
    return {
      modelType: this.model_type,
      searchResults: [],
      searchQuery: null
    }
  },

  methods: {
    filterFn (val, update, abort) {
      if (val.length < 2) {
        abort()
        return
      }

      const model_type = this.modelType.split('.').pop()
      this.$axios.post('/api/search', { model_type, req: val.toLowerCase(), ...this.params })
        .then(response => response.data)
        .then(results => { update(() => { this.searchResults = results }) })
        .catch()
        .finally()
    },

    onResultSelect (val) {
      this.$emit('input', { type: this.modelType, value: val })
      this.searchQuery = val
    },

    reset () {
      this.modelType = null
      this.searchResults = []
      this.searchQuery = null
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
