export function setSousCibles (state, cibles) {
  state.cibles = cibles
}

export function setFormFields (state, formFields) {
  state.formFields = formFields
}

export function setFilters (state, filters) {
  state.filters = filters.map(filter => ({
    ...filter, value: filter.value ? filter.value : null
  }))
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setCurrentSousCible (state, cible) {
  state.currentSousCible = cible
}
