export function getUsers (state) {
  return state.users
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrentUser (state) {
  return state.currentUser
}
