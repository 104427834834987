import { mapState } from 'vuex'

export default {
  props: {
    'form_model': { type: String, required: true },
    'action': { type: String, required: true }
  },

  data () {
    return {
      pending: false,
      hasChanged: false,
      formData: {}
    }
  },

  computed: {
    ...mapState({
      formFields (state, getters) {
        const fields = getters[`${this.form_model}/getFormFields`]
        return fields && fields.map((field) => ({
          ...field,
          label: `${this.form_model}.fields.${field.id}.label`,
          options: field.options && field.options.map(({ label, value }) => ({
            value, label: typeof label === 'string' ? `${this.form_model}.fields.${field.id}.options.${label}` : label
          }))
        }))
      }
    })
  },

  watch: {
    formData: {
      handler (newVal, oldVal) {
        if (Object.keys(newVal).length > 0 && Object.keys(oldVal).length > 0) {
          this.hasChanged = true
        }
      },
      deep: true
    }
  },

  mounted () {
    if (!this.$route.params.id) {
      if (this.formFields) this.init()
    }
  },

  methods: {
    init () {
      this.formFields.forEach(({ id, type, default: defaultVal }) => {
        const input = this.$refs[id]
        if (input && defaultVal) {
          input.$emit('input', type === 'select' ? {
            label: this.$t(`${this.form_model}.fields.${id}.options.${defaultVal}`),
            value: defaultVal
          } : defaultVal)
        }
      })
    },
    hasError () {
      return this.formFields.some(({ id, required, value }) => {
        if (this.$refs[id]) {
          if (typeof this.$refs[id].hasError === 'function') return this.$refs[id].hasError()
          else return this.$refs[id].hasError || (this.$refs[id].required && value === null)
        }

        return false
      })
    },

    save () {
      this.validate()

      if (this.hasError()) {
        this.notifyError('form.missing_fields')
      } else {
        this.pending = true

        let params = { ...this.formData }

        this.formFields.forEach(({ id, type }) => {
          if (!this.formData[id]) return
          if (type === 'select') {
            params[id] = this.formData[id].value
          } else if (type === 'json' && this.formData[id]) {
            params[id] = JSON.stringify(this.formData[id])
          } else if (type === 'number') {
            params[id] = Number(this.formData[id])
          } else {
            params[id] = this.formData[id]
          }
        })

        return this.$store.dispatch(this.action, params)
          .then(response => {
            this.hasChanged = false
            this.onSave(response)
            this.notifySuccess()
            this.$emit('success')
          }).catch(error => {
            this.onError(error)
          }).finally(() => { this.pending = false })
      }
    },

    onSave () {
    },
    onError (error) {
      this.notifyError(error.response.data)
    },
    validate () {
      this.formFields.forEach(({ id, type, options }) => {
        const input = this.$refs[id]
        !!input && input.required && typeof input.validate === 'function' && input.validate()
      })
    },

    reset () {
      this.formFields.forEach(({ id, type, options }) => {
        const input = this.$refs[id]
        if (input) {
          input.resetValidation()
          input.$emit('input', null)
        }
        this.$emit('reset')
        this.hasChanged = false
      })
    },

    formInputProps (field_id) {
      return this.formFields.find(({ id }) => id === field_id)
    },

    notifySuccess (message) {
      return this.$q.notify({
        message: this.$t(`${this.form_model}.save_success`),
        color: 'positive'
      })
    },

    notifyError (error) {
      return this.$q.notify({
        message: this.$t(error),
        color: 'negative'
      })
    }
  }
}
