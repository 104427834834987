import regions from './regions'
import statuts from './statuts'
import qualifications from './qualifications'
import sousCibles from './sousCibles'

export default {
  cibles_list: 'Liste des cibles',
  cibles: 'Cibles',
  cible: 'Cible',
  fields: {
    label: { label: 'Titre de la cible' },
    description: { label: 'Description' },
    admin_note: { label: 'Commentaire' },
    date: { label: 'Date visée' },
    date_validation: { label: 'Date de validation cible' },
    statut: { label: 'État', options: statuts },
    qualification: { label: 'Météo', options: qualifications },
    cibles: { label: 'Sous-cibles' },
    model: { label: 'Objet(s) lié(s)' },
    date_realisation: { label: 'Date de réalisation' },
    switch_meteo: { label: 'Météo' },
    switch_comptage: { label: 'Comptage' }
  },
  filters: {
    type: { label: 'Type', options: sousCibles.types },
    region_id: { label: 'Région', options: regions },
    qualification: { label: 'Météo', options: qualifications },
    statut: { label: 'État', options: statuts },
    date_visee_start: { label: 'Après le' },
    date_visee_end: { label: 'Avant le' },
    date_planification_start: { label: 'Validée après le' },
    date_planification_end: { label: 'Validée avant le' },
    groupe_cible: { label: 'Cibles' },
    cible: { label: 'Sous-cibles' },
    date_realisation: { label: 'Date de réalisation' },
    switch_meteo: { label: 'Météo' },
    switch_comptage: { label: 'Comptage' },
    comptage: { label: 'Comptage' }
  },
  add_cible: 'Créer une cible',
  edit_cible: 'Modifier la cible',
  new_cible: 'Nouvelle cible',
  delete_cible: 'Supprimer',
  delete_cible_confirm: 'Êtes-vous sûr de vouloir supprimer cette cible ?',
  save_success: 'Cible sauvegardée',
  delete_success: 'Cible supprimée',
  export: 'Exporter',
  export_evolution: 'Exporter l\'évolution',
  detailled_export: 'Export détaillé',
  integrer_stats_PER: 'Intégrer aux stats PER',
  edit_date: 'Modifier date',
  select_all: 'Tout séléctionner'
}
