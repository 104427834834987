export default {
  notes: 'Notes perso',
  note: 'Note',
  fields: {
    contenu: { label: 'Contenu' }
  },
  add_note: 'Ajouter une note',
  edit_note: 'Modifier la note',
  create_note: 'Créer une note',
  delete_note: 'Supprimer',
  delete_note_confirm: 'Êtes-vous sûr de vouloir supprimer cette note ?',
  save_success: 'Note sauvegardée',
  delete_success: 'Note supprimée'
}
