export function getProcesses (state) {
  return state.processes
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrentProcess (state) {
  return state.currentProcess
}
