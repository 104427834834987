import regions from './regions'
import statuts from './statuts'
import qualifications from './qualifications'

const types = {
  CREATION_ENTITE: 'Création d\'une entité',
  CREATION_POSTE: 'Création d\'un poste',
  SUPPRESSION_POSTE: 'Suppression d\'un poste',
  EMBAUCHE_COMMERCIAL: 'Embauche d\'un commercial',
  RECODIFICATION_PORTEFEUILLE: 'Recodiffication d\'un portefeuille AG+CM en AG seul',
  NOMINATION: 'Nomination',
  CESSE_FONCTIONS: 'Cesse ses fonctions',
  CHANGE_ENTITE: 'Change d\'entité',
  CHANGEMENT_TITULAIRE: 'Changement de titulaire d\'un portefeuille',
  CHANGEMENT_TITULAIRE_MANDAT_GESTION: 'Changement de titulaire d\'un portefeuille en mandat de gestion',
  CREATION_PORTEFEUILLE: 'Création d\'un portefeuille',
  CLOTURE_PORTEFEUILLE: 'Cloture d\'un portefeuille',
  TRANSFERTS_PORTEFEUILLE: 'Tranferts portefeuille',
  TRANSFERT_PARTIEL_PORTEFEUILLE: 'Transfert partiel de portefeuille',
  OUVERTURE_PDV: 'Ouverture d\'un point de vente',
  FERMETURE_PDV: 'Fermeture d\'un point de vente',
  DEPLACEMENT_PDV: 'Déplacement d\'un point de vente'
}

export default {
  sousCibles: 'Sous-cibles',
  sousCible: 'Sous-cible',
  fields: {
    type: { label: 'Type', options: types },
    date: { label: 'Date' },
    model: { label: 'Objet(s) lié(s)' },
    statut: { label: 'État', options: statuts },
    qualification: { label: 'Météo', options: qualifications },
    region_id: { label: 'Région', options: regions },
    virtual_entity_name: { label: 'Nom de la nouvelle entité' },
    virtual_wallet_name: { label: 'Nom du nouveau portefeuille' },
    virtual_agent_name: { label: 'Nom du nouvel agent' },
    date_realisation: { label: 'Date de réalisation' },
    date_validation: { label: 'Date de validation' },
    switch_meteo: { label: 'Météo' },
    switch_comptage: { label: 'Comptage' },
    comptage: { label: 'Comptage' }
  },
  filters: {
    statut: { label: 'État', options: statuts },
    region: { label: 'Région', options: regions }
  },
  add_sousCible: 'Ajouter une sous-cible',
  edit_sousCible: 'Modifier la sous-cible',
  new_sousCible: 'Nouvelle sous-cible',
  delete_sousCible: 'Supprimer',
  delete_sousCible_confirm: 'Êtes-vous sûr de vouloir supprimer cette sous-cible ?',
  save_success: 'Cible sauvegardée',
  delete_success: 'Cible supprimée',
  export: 'Exporter',
  types
}
