const routes = [
  {
    path: '/',
    meta: { validate: ['auth'] },
    component: () => import('layouts/MyLayout.vue'),
    children: [
      { path: '', name: 'home', redirect: '/entities' },

      { path: 'users',
        meta: { model: 'user', color: 'users', section: 'users.users' },
        component: () => import('pages/users/Users.vue'),
        children: [
          { path: '', name: 'users-list', component: () => import('pages/users/UsersList.vue') }
        ] },

      { path: 'user',
        meta: { color: 'users', section: 'users.users' },
        component: () => import('pages/users/User.vue'),
        children: [
          { path: 'new', name: 'user-create', component: () => import('pages/users/UserDetails.vue') },
          { path: ':id', name: 'user-show', component: () => import('pages/users/UserDetails.vue') }
        ] },

      { path: 'entities',
        meta: { model: 'entity', color: 'reseau', section: 'global.entities' },
        component: () => import('pages/entities/Entities.vue'),
        children: [
          { path: '', name: 'entities-list', component: () => import('pages/entities/EntitiesList.vue') },
          { path: 'map', name: 'entities-map', component: () => import('pages/entities/EntitiesMap.vue') }
        ] },

      { path: 'entity/:id/',
        meta: { color: 'reseau', model: 'entity', section: 'global.entities' },
        component: () => import('pages/entities/Entity.vue'),
        children: [
          { path: '', name: 'entity-show', component: () => import('pages/entities/EntityInformations.vue') },
          { path: 'map', name: 'entity-map', component: () => import('pages/entities/EntityMap.vue') },
          { path: 'evenements', name: 'entity-evenements', component: () => import('pages/entities/EntityEvenements.vue') },
          { path: 'tasks', name: 'entity-tasks', component: () => import('pages/entities/EntityTasks.vue') },
          { path: 'cibles', name: 'entity-cibles', component: () => import('pages/entities/EntityCibles.vue') },
          { path: 'notes', name: 'entity-notes', component: () => import('pages/entities/EntityNotes.vue') }
        ] },

      { path: 'cibles',
        meta: { model: 'cible', color: 'per', section: 'global.per.per' },
        component: () => import('pages/cibles/Cibles.vue'),
        children: [
          { path: '', name: 'cibles-list', component: () => import('pages/cibles/CiblesList.vue') },
          { path: 'map', name: 'cibles-map', component: () => import('pages/cibles/CiblesMap.vue') },
          { path: 'timeline', name: 'cibles-timeline', component: () => import('pages/cibles/CiblesTimelinePage.vue') }
        ]
      },
      {
        path: 'processes',
        meta: { model: 'process', color: 'process', section: 'global.process' },
        component: () => import('pages/process/Processes-list.vue'),
        children: [
          { path: 'suivi-de-recrutement-en-cours', name: 'suivi-de-recrutement-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDeRecrutement/liste.vue') },
          { path: 'suivi-de-recrutement-archives', name: 'suivi-de-recrutement-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDeRecrutement/liste.vue') },
          { path: 'suivi-de-nomination-en-cours', name: 'suivi-de-nomination-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDeNomination/liste.vue') },
          { path: 'suivi-de-nomination-archives', name: 'suivi-de-nomination-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDeNomination/liste.vue') },
          { path: 'suivi-de-periode-probatoire-en-cours', name: 'suivi-de-periode-probatoire-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDePeriodeProbatoire/liste.vue') },
          { path: 'suivi-de-periode-probatoire-archives', name: 'suivi-de-periode-probatoire-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDePeriodeProbatoire/liste.vue') },
          { path: 'suivi-des-ic-en-cours', name: 'suivi-des-ic-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDesIc/liste.vue') },
          { path: 'suivi-des-ic-archives', name: 'suivi-des-ic-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDesIc/liste.vue') },
          { path: 'suivi-des-departs-en-cours', name: 'suivi-des-departs-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDesDeparts/liste.vue') },
          { path: 'suivi-des-departs-archives', name: 'suivi-des-departs-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDesDeparts/liste.vue') },
          { path: 'suivi-de-mandat-gestion-en-cours', name: 'suivi-de-mandat-gestion-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDeMandatGestion/liste.vue') },
          { path: 'suivi-de-mandat-gestion-archives', name: 'suivi-de-mandat-gestion-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDeMandatGestion/liste.vue') },
          { path: 'suivi-des-delegations-en-cours', name: 'suivi-des-delegations-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDesDelegations/liste.vue') },
          { path: 'suivi-des-delegations-archives', name: 'suivi-des-delegations-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDesDelegations/liste.vue') },
          { path: 'suivi-de-transfert-portefeuille-en-cours', name: 'suivi-de-transfert-portefeuille-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDeTransfertPortefeuille/liste.vue') },
          { path: 'suivi-de-transfert-portefeuille-archives', name: 'suivi-de-transfert-portefeuille-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDeTransfertPortefeuille/liste.vue') },
          { path: 'ouverture-gi-en-cours', name: 'ouverture-gi-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/OuvertureGi/liste.vue') },
          { path: 'ouverture-gi-archives', name: 'ouverture-gi-archives', meta: { filter: 'archive' }, component: () => import('pages/process/OuvertureGi/liste.vue') },
          { path: 'fermeture-gi-en-cours', name: 'fermeture-gi-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/FermetureGi/liste.vue') },
          { path: 'fermeture-gi-archives', name: 'fermeture-gi-archives', meta: { filter: 'archive' }, component: () => import('pages/process/FermetureGi/liste.vue') },
          { path: 'suivi-des-protocoles-en-cours', name: 'suivi-des-protocoles-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDesProtocoles/liste.vue') },
          { path: 'suivi-des-protocoles-archives', name: 'suivi-des-protocoles-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDesProtocoles/liste.vue') },
          { path: 'suivi-des-demenagements-en-cours', name: 'suivi-des-demenagements-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDesDemenagements/liste.vue') },
          { path: 'suivi-des-demenagements-archives', name: 'suivi-des-demenagements-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDesDemenagements/liste.vue') },
          { path: 'suivi-de-dossier-signaletique-en-cours', name: 'suivi-de-dossier-signaletique-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDeDossierSignaletique/liste.vue') },
          { path: 'suivi-de-dossier-signaletique-archives', name: 'suivi-de-dossier-signaletique-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDeDossierSignaletique/liste.vue') },
          { path: 'suivi-des-aides-en-cours', name: 'suivi-des-aides-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDesAides/liste.vue') },
          { path: 'suivi-des-aides-archives', name: 'suivi-des-aides-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDesAides/liste.vue') },
          { path: 'suivi-des-aides-admin-en-cours', name: 'suivi-des-aides-admin-en-cours', meta: { filter: 'en-cours' }, component: () => import('pages/process/SuiviDesAidesAdmin/liste.vue') },
          { path: 'suivi-des-aides-admin-archives', name: 'suivi-des-aides-admin-archives', meta: { filter: 'archive' }, component: () => import('pages/process/SuiviDesAidesAdmin/liste.vue') }
          // { path: '', name: 'process-list', component: () => import('pages/process/processesList.vue') }
        ]
      },
      {
        path: 'process',
        meta: { model: 'process', color: 'process', section: 'global.process' },
        component: () => import('pages/process/Processes-fiche.vue'),
        children: [
          { path: 'candidat/:id?', name: 'candidat-fiche', meta: { process: 'recrutement' }, component: () => import('pages/process/SuiviDeRecrutement/candidat.vue') },
          { path: 'portefeuille-confie/:id?', name: 'portefeuille-confie-fiche', component: () => import('pages/process/SuiviDeNomination/portefeuilleConfie.vue') },
          { path: 'suivi-de-recrutement/:id?', name: 'suivi-de-recrutement-form', meta: { }, component: () => import('pages/process/SuiviDeRecrutement/fiche.vue') },
          { path: 'suivi-de-nomination/:id?', name: 'suivi-de-nomination-form', meta: { }, component: () => import('pages/process/SuiviDeNomination/fiche.vue') },
          { path: 'suivi-de-recrutement-candidat/:processId?', name: 'suivi-de-recrutement-form-candidat', meta: { process: 'recrutement' }, component: () => import('pages/process/SuiviDeRecrutement/candidat.vue') },
          { path: 'suivi-de-nomination-portefeuille-confie/:processId?', name: 'suivi-de-nomination-form-portefeuille-confie', meta: { process: 'nomination' }, component: () => import('pages/process/SuiviDeNomination/portefeuilleConfie.vue') },
          { path: 'suivi-de-periode-probatoire/:id?', name: 'suivi-de-periode-probatoire-form', meta: { }, component: () => import('pages/process/SuiviDePeriodeProbatoire/fiche.vue') },
          { path: 'suivi-de-mandat-ferme/:id?', name: 'suivi-de-mandat-ferme-form', meta: { isMandatFerme: true }, component: () => import('pages/process/SuiviDePeriodeProbatoire/fiche.vue') },
          { path: 'suivi-des-ic/:id?', name: 'suivi-des-ic-form', meta: { }, component: () => import('pages/process/SuiviDesIc/fiche.vue') },
          { path: 'suivi-des-ic/:suiviDesIcId/echeances', name: 'echeance-fiche', component: () => import('pages/process/SuiviDesIc/echeances.vue') },
          { path: 'suivi-des-departs/:id?', name: 'suivi-des-departs-form', meta: { }, component: () => import('pages/process/SuiviDesDeparts/fiche.vue') },
          { path: 'suivi-de-mandat-gestion/:id?', name: 'suivi-de-mandat-gestion-form', meta: { }, component: () => import('pages/process/SuiviDeMandatGestion/fiche.vue') },
          { path: 'suivi-des-delegations/:id?', name: 'suivi-des-delegations-form', meta: { }, component: () => import('pages/process/SuiviDesDelegations/fiche.vue') },
          { path: 'suivi-de-transfert-portefeuille/:id?', name: 'suivi-de-transfert-portefeuille-form', meta: { }, component: () => import('pages/process/SuiviDeTransfertPortefeuille/fiche.vue') },
          { path: 'ouverture-gi/:id?', name: 'ouverture-gi-form', meta: { }, component: () => import('pages/process/OuvertureGi/fiche.vue') },
          { path: 'fermeture-gi/:id?', name: 'fermeture-gi-form', meta: { }, component: () => import('pages/process/FermetureGi/fiche.vue') },
          { path: 'suivi-des-protocoles/:id?', name: 'suivi-des-protocoles-form', meta: { }, component: () => import('pages/process/SuiviDesProtocoles/fiche.vue') },
          { path: 'suivi-des-demenagements/:id?', name: 'suivi-des-demenagements-form', meta: { }, component: () => import('pages/process/SuiviDesDemenagements/fiche.vue') },
          { path: 'suivi-de-dossier-signaletique/:id?', name: 'suivi-de-dossier-signaletique-form', meta: { }, component: () => import('pages/process/SuiviDeDossierSignaletique/fiche.vue') },
          { path: 'suivi-des-aides/:id?', name: 'suivi-des-aides-form', meta: { }, component: () => import('pages/process/SuiviDesAides/fiche.vue') },
          { path: 'suivi-des-aides-admin/:id?', name: 'suivi-des-aides-admin-form', meta: { }, component: () => import('pages/process/SuiviDesAidesAdmin/fiche.vue') },
          { path: 'console-admin/stage', name: 'stage-fiche', component: () => import('pages/process/ConsoleAdministration/ficheStage.vue') },
          { path: 'console-admin/objectifs', name: 'objectif-fiche', component: () => import('pages/process/ConsoleAdministration/ficheObjectif.vue') }
        ]
      },

      { path: 'cible/new',
        meta: { color: 'per', section: 'global.per.per' },
        component: () => import('pages/cibles/Cible.vue'),
        children: [
          { path: '', props: true, name: 'cible-create', component: () => import('pages/cibles/CibleDetails.vue') }
        ] },

      { path: 'cible/:id/',
        component: () => import('pages/cibles/Cible.vue'),
        meta: { color: 'per', section: 'global.per.per' },
        children: [
          { path: '', name: 'cible-show', component: () => import('pages/cibles/CibleDetails.vue') },
          { path: 'evenements', name: 'cible-evenements', component: () => import('pages/cibles/CibleEvenements.vue') },
          { path: 'notes', name: 'cible-notes', component: () => import('pages/cibles/CibleNotes.vue') }
        ] },

      { path: 'sous-cible/new',
        meta: { color: 'per', section: 'global.per.per' },
        component: () => import('pages/sousCibles/SousCible.vue'),
        children: [
          { path: '', props: true, name: 'sous-cible-create', component: () => import('pages/sousCibles/SousCibleDetails.vue') }
        ] },

      { path: 'sous-cible/:id/',
        meta: { color: 'per', section: 'global.per.per' },
        component: () => import('pages/sousCibles/SousCible.vue'),
        children: [
          { path: '', name: 'sous-cible-show', meta: { isDetail: true }, component: () => import('pages/sousCibles/SousCibleDetails.vue') }
        ] },

      { path: 'tasks',
        meta: { model: 'task', color: 'tasks', section: 'global.tasks' },
        component: () => import('pages/tasks/Tasks.vue'),
        children: [
          { path: '', name: 'tasks-list', redirect: 'en-cours' },
          { path: 'en-cours', name: 'tasks-list-en-cours', meta: { statut: 'EN_COURS' }, component: () => import('pages/tasks/TasksList.vue') },
          { path: 'tous', name: 'tasks-list-tous', meta: { statut: null }, component: () => import('pages/tasks/TasksList.vue') },
          { path: 'brouillon', name: 'tasks-list-brouillon', meta: { statut: 'BROUILLON' }, component: () => import('pages/tasks/TasksList.vue'), alias: 'tasks-list' },
          { path: 'a-valider', name: 'tasks-list-a-valider', meta: { statut: 'A_VALIDER' }, component: () => import('pages/tasks/TasksList.vue') },
          { path: 'abandonnee', name: 'tasks-list-abandonnee', meta: { statut: 'ABANDONNE' }, component: () => import('pages/tasks/TasksList.vue') },
          { path: 'planifiee', name: 'tasks-list-planifiee', meta: { statut: 'PLANIFIE' }, component: () => import('pages/tasks/TasksList.vue') },
          { path: 'terminee', name: 'tasks-list-terminee', meta: { statut: 'TERMINE' }, component: () => import('pages/tasks/TasksList.vue') }
        ] },

      { path: 'task/new',
        meta: { color: 'tasks', section: 'global.tasks' },
        component: () => import('pages/tasks/Task.vue'),
        children: [
          { path: '', name: 'task-create', props: true, component: () => import('pages/tasks/TaskDetails.vue') }
        ] },

      { path: 'task/:id',
        meta: { model: 'task', color: 'tasks', section: 'global.tasks' },
        component: () => import('pages/tasks/Task.vue'),
        children: [
          { path: '', name: 'task-show', component: () => import('pages/tasks/TaskDetails.vue') },
          { path: 'comments', name: 'task-comments', component: () => import('pages/tasks/TaskComments.vue') }
        ] },

      { path: 'per',
        meta: { color: 'per', section: 'global.per.per' },
        component: () => import('pages/per/PER.vue'),
        children: [
          { path: 'comptage-reseau', name: 'comptage-reseau', component: () => import('pages/per/ComptageReseau.vue') },
          { path: 'dashboard', name: 'dashboard-per', component: () => import('pages/per/Dashboard.vue') }
        ] },

      { path: 'comite',
        meta: { color: 'comite', section: 'global.comite' },
        component: () => import('pages/comite/Comite.vue'),
        children: [
          { path: '', meta: { model: 'instance' }, name: 'instances-list', component: () => import('pages/comite/InstancesList.vue') },
          { path: 'compte-rendus', meta: { model: 'instance' }, name: 'compte-rendus-list', component: () => import('pages/comite/InstancesList.vue') },
          { path: 'sujets', meta: { model: 'sujet' }, name: 'sujets-list', component: () => import('pages/comite/SujetsList.vue') },
          { path: 'sujets-a-planifier', meta: { model: 'sujet' }, name: 'sujets-a-planifier-list', component: () => import('pages/comite/SujetsList.vue') }
        ] },

      { path: 'comite/instance',
        meta: { color: 'comite', model: 'instance', section: 'global.comite' },
        component: () => import('pages/comite/Instance.vue'),
        children: [
          { path: 'new', name: 'instance-create', component: () => import('pages/comite/InstanceDetails.vue') },
          { path: ':id', name: 'instance-show', component: () => import('pages/comite/InstanceDetails.vue') }
        ] },

      { path: 'comite/sujet',
        meta: { color: 'comite', model: 'sujet', section: 'global.comite' },
        component: () => import('pages/comite/Sujet.vue'),
        children: [
          { path: 'new', name: 'sujet-create', component: () => import('pages/comite/SujetDetails.vue') },
          { path: ':id', name: 'sujet-show', component: () => import('pages/comite/SujetDetails.vue') }
        ] },

      { path: 'divergence',
        name: 'divergence',
        meta: { color: 'divergence', model: 'divergence', section: 'global.divergence' },
        component: () => import('pages/divergence/Divergence.vue')
      }
    ]
  },

  {
    path: '/',
    meta: { validate: ['guest'] },
    component: () => import('layouts/BaseLayout.vue'),
    children: [
      { path: '/login', name: 'login', component: () => import('pages/auth/Login.vue') },
      { path: '/lost-password', component: () => import('pages/auth/LostPassword.vue') },
      { path: '/reset-password/:token', component: () => import('pages/auth/ResetPassword.vue') }
    ]
  }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/Error404.vue')
  })
}

export default routes
