export const status = {
  BROUILLON: 'Brouillon',
  EN_COURS: 'En cours',
  SHORTLIST: 'Shortlist',
  RETENU: 'Retenu',
  TERMINE: 'Terminé',
  ABANDONNE: 'Abandonné',
  CANDIDAT: 'Candidat',
  MANDAT_FERME: 'Mandat ferme',
  AFFECTE: 'Affecté',
  PROPOSE_RPPR: 'Proposé RPPR',
  PROPOSE_SIEGE: 'Proposé Siège',
  PREVISIONNEL: 'Prévisionnel',
  REEL: 'Réel'
}
export const titularisations = {
  TITULARISE: 'Titularisé',
  NON_TITULARISE: 'Non titularisé',
  MANDAT_FERME: 'Mandat ferme'
}
export const avisRegions = {
  TITULARISATION: 'Titularisation',
  NON_TITULARISATION: 'Non titularisation',
  MANDAT_FERME: 'Mandat ferme'
}
export const type = {
  IC: 'IC',
  GRE_A_GRE: 'Gré à gré',
  PROTOCOLE: 'Protocole',
  AGENCE_ROUGE: 'Agence rouge'
}
export const typeAideOptions = {
  ACO: 'ACO',
  ACR: 'ACR',
  PONCTUEL: 'Ponctuel',
  RECRUTEMENT_DE_COMMERCIAUX: 'Recrutement de commerciaux',
  CREATION_PDV: 'Création pdv',
  AUTRE: 'Autre'
}

export const natures = {
  CESSATION: 'Cessation',
  DEPART: 'Départ',
  NOMINATION: 'Nomination',
  TRANSFERT: 'Transfert',
  MANDAT_GESTION: 'Mandat gestion'
}

export const typeDeBails = {
  COMMERCIAL: 'Commercial',
  MIXTE: 'Mixte',
  PROFESSIONNEL: 'Professionnel'
}

export const motifDeparts = {
  NON_TITULARISATION: 'Non titularisation',
  DEMISSION: 'Démission',
  DEMISSION_PROVOQUEE: 'Démission provoquée',
  REVOCATION: 'Révocation',
  RETRAITE: 'Retraite',
  DECES: 'Décès'
}

export const statutCompteGestions = {
  NON_REGLE: 'Non réglé',
  REGLE: 'Réglé'
}

export const typeDeTransferts = {
  TOTAL: 'Total',
  PARTIEL: 'Partiel'
}
export const origineBesoin = {
  DEMENAGEMENT: 'Déménagement',
  NOUVEAU_LOCAL: 'Nouveau local',
  MISE_AUX_NORMES: 'Mises aux normes'
}
export const periodicites = {
  MENSUELLE: 'Mensuelle',
  TRIMESTRIELLE: 'Trimestrielle',
  SEMESTRIELLE: 'Semestrielle',
  AUTRE: 'Autre'
}

import regions from './regions'

export default {
  status: status,
  motif_departs: motifDeparts,
  titularisations: titularisations,
  origineBesoin: origineBesoin,
  title: {
    processes: 'Process agence',
    suivi_de_recrutement: 'Suivi de recrutement',
    suivi_de_nomination: 'Suivi de nomination',
    suivi_de_periode_probatoire: 'Suivi de période probatoire',
    suivi_de_mandat_ferme: 'Suivi mandat ferme',
    suivi_des_ic: 'Suivi des IC',
    suivi_des_departs: 'Suivi des départs',
    suivi_de_mandat_gestion: 'Suivi de mandat de gestion',
    suivi_des_delegations: 'Suivi des délégations',
    suivi_de_transfert_portefeuille: 'Suivi de transfert de portefeuille',
    ouverture_gi: 'Ouverture de GI',
    fermeture_gi: 'Fermeture de GI',
    suivi_des_protocoles: 'Suivi des protocoles et des agences rouges',
    suivi_des_demenagements: 'Suivi des déménagements',
    suivi_de_dossier_signaletique: 'Suivi de dossier signalétique',
    suivi_des_aides: 'Suivi des aides',
    suivi_des_aides_admin: 'Suivi des aides admin',
    console_administration: 'Console administration',
    administration: 'Administration'
  },
  fields: {
    model: { label: 'Model' },
    wallets: { label: 'Portefeuilles' },
    montant: { label: 'Montant' },
    dateEcheance: { label: 'Date d\'échéance' },
    datePaiement: { label: 'Date de paiement' },
    paiementEffectue: { label: 'Paiement effectué' },
    statut: { label: 'État', options: status },
    date_publication: { label: 'Date de publication' },
    date_ouverture_annonce: { label: 'Date d\'ouverture de l\'annonce' },
    date_cible: { label: 'Date cible' },
    date_cible_reorganisation: { label: 'Date cible de réorganisation' },
    adresse: { label: 'Adresse' },
    secteur_geographique: { label: 'Secteur géographique' },
    date_rappel: { label: 'Date de rappel' },
    green_light: { label: 'Feu vert Direction commerciale' },
    nom: { label: 'Nom' },
    prenom: { label: 'Prénom' },
    wallet: { label: 'Portefeuile' },
    process: { label: 'Process' },
    etat: { label: 'Etat' },
    candidat: { label: 'Candidat' },
    date_nomination: { label: 'Date de nomination' },
    conformite_lettre_agrement: { label: 'Conforme à la lettre d\'agrément' },
    type: { label: 'Type', options: type },
    date_fin_periode_probatoire: { label: 'Date de fin de période probatoire' },
    date_signature_traite_nomination: { label: 'Date de signature du traité de nomination' },
    motif_ko: { label: 'Motif du KO' },
    montant_cheque_apport: { label: 'Montant du chèque d\'apport' },
    date_reception_cheque_apport: { label: 'Date de réception du chèque' },
    'conformite_lettre_d’agrement': { label: 'Conforme à la lettre d\'agrément' },
    date_reception_regime_RC_pro: { label: 'Date de réception RC pro' },
    date_adhesion_prevoyance_sante: { label: 'Date d\'adhésion prévoyance et santé' },
    date_communication_adresse_fiscale: { label: 'Date de communication de l\'adresse fiscale' },
    numero_de_SIREN: { label: 'Numéro de SIREN' },
    date_declaration_CAVAMAC: { label: 'Date de déclaration CAVAMAC' },
    matricule_CAVAMAC: { label: 'Numéro matricule CAVAMAC' },
    date_demande_ORIAS: { label: 'Date de demande d\'immatriculation ORIAS' },
    numero_ORIAS: { label: 'Numéro d\'immatriculation ORIAS' },
    date_reception_RIB: { label: 'Date réception RIB Personnel' },
    date_reception_formulaire_CBC: { label: 'Date réception formulaire CBC' },
    lettre_ATGA_affectation_patrimoniale: { label: 'Lettre ATGA ou Affectation patrimoniale' },
    taux_detention: { label: 'Taux' },
    nomPtfConfie: { label: 'Nom du portefeuille confié' },
    agentList: { label: 'Liste des agents' },
    titularisation: { label: 'Titularisation' },
    agent: { label: 'Agent' },
    date_fin: { label: 'Date de fin' },
    avis_region: { label: 'Avis de la région', options: avisRegions },
    commentaire_region: { label: 'Commentaires' },
    avis_siege: { label: 'Avis du siège', options: avisRegions },
    commentaire_siege: { label: 'Commentaire' },
    decision_dir_comm: { label: 'Décision Direction Commerciale', options: titularisations },
    commentaire_dir_comm: { label: 'Commentaire' },

    date_reception_bilan_3M: { label: 'Date de reception du bilan' },
    date_reception_bilan_6M: { label: 'Date de reception du bilan' },
    date_reception_bilan_9M: { label: 'Date de reception du bilan' },
    date_reception_bilan_12M: { label: 'Date de reception du bilan' },
    date_reception_bilan_18M: { label: 'Date de reception du bilan' },
    date_comite_regional_3M: { label: 'Date de passage en comité' },
    date_comite_regional_6M: { label: 'Date de passage en comité' },
    date_comite_regional_9M: { label: 'Date de passage en comité' },
    date_comite_regional_12M: { label: 'Date de passage en comité' },
    date_comite_regional_18M: { label: 'Date de passage en comité' },
    date_relance_bilan_3M: { label: 'Date de relance' },
    date_relance_bilan_6M: { label: 'Date de relance' },
    date_relance_bilan_9M: { label: 'Date de relance' },
    date_relance_bilan_12M: { label: 'Date de relance' },
    date_relance_bilan_18M: { label: 'Date de relance' },

    nature: { label: 'Nature', options: natures },
    date: { label: 'Date' },
    date_evenement: { label: 'Date de l\'évènement' },
    statut_initial_compte_gestion: { label: 'Statut initial du compte de fin de gestion', options: statutCompteGestions },
    date_validation: { label: 'Date d\'acceptation du compte de fin de gestion' },
    position_compte: { label: 'Position du compte à date' },
    statut_agent: { label: 'Statut de l\'agent' },
    date_demande_listing_yard: { label: 'Date de demande de listing IARD' },
    date_validation_calcul_yard: { label: 'Date de validation de calcul IARD & SANTE' },
    date_demande_calcul_ic: { label: 'Date de demande du calcul Vie' },
    date_validation_calcul_ic: { label: 'Date de validation du calcul Vie' },
    motif: { label: 'Motif de départ', options: motifDeparts },
    motif_depart: { label: 'Motif de départ' },
    date_depart_list: { label: 'Date de départ' },
    date_depart: { label: 'Date de départ (dernier jour en qualité d\'agent)' },
    statut_compte_fin_gestion_initial: { label: 'Statut du compte de fin de gestion', options: statutCompteGestions },
    date_initialisation_CFG: { label: 'Date d\'initialisation du CFG' },
    date_finalisation_CFG: { label: 'Date de finalisation du CFG' },
    usage_droit_presentation: { label: 'Usage du droit de présentation' },
    date_notification_droit_presentation: { label: 'Date de notification du droit de présentation' },
    date_radiation_ORIAS: { label: 'Date de radiation ORIAS' },
    date_radiation_CAVAMAC: { label: 'Date de radiation CAVAMAC' },
    date_mise_a_jour_ICX: { label: 'Date MAJ ICX' },
    date_avis_service: { label: 'Date avis aux services' },
    acces_recrutement: { label: 'Accès recrutement' },
    acces_transmission: { label: 'Accès transmission' },
    date_initialisation_process_analyse_portefeuille: { label: 'Date' },
    date_finalisation_dossier_destination_transmission: { label: 'Date de finalisation' },
    date_courrier: { label: 'Date du courrier' },
    issue: { label: 'Terme du mandat' },
    motif_mandat_gestion: { label: 'Motif du mandat de gestion' },
    date_debut: { label: 'Date de début' },
    part: { label: 'Pourcentage en mandat de gestion' },
    commission_yard: { label: 'Montant de la comm. IARD' },
    commission_sante: { label: 'Montant de la comm. Santé' },
    commission_vie: { label: 'Montant de la comm. Vie' },
    loyer: { label: 'Montant du loyer' },
    date_signature_mandat: { label: 'Date de signature du mandat' },
    date_prorogation_mandat: { label: 'Date de prorogation du mandat' },
    mandat_gestion_termine: { label: 'Mandat gestion terminé' },
    commentaires: { label: 'Commentaires' },
    date_effet: { label: 'Date d\'effet' },
    date_previsionnelle_fin_pret: { label: 'Date prévisionnelle de fin de prêt' },
    nom_banque: { label: 'Nom de la banque' },
    adresse_banque: { label: 'Adresse' },
    reference_pret: { label: 'Référence du prêt' },
    montant_pret: { label: 'Montant du prêt' },
    date_envoi_courrier: { label: 'Date d\'envoi du courrier à la banque' },
    date_main_levee: { label: 'Date' },
    date_prev_fin_de_pret: { label: 'Date prév fin de prêt' },
    type_transfert: { label: 'Type de transfert', options: typeDeTransferts },
    date_transfert: { label: 'Date de transfert' },
    date_accord_agent_perdant: { label: 'Date de l\'accord agent perdant' },
    date_accord_agent_gagnant: { label: 'Date de l\'accord agent gagnant' },
    wallet_initial: { label: 'Portefeuille initial' },
    wallet_cible: { label: 'Portefeuille cible' },

    // Ouverture GI
    periodicite: { label: 'Périodicité', options: periodicites },
    date_ouverture: { label: 'Date d\'ouverture de la GI' },
    date_previsionnelle_fermeture: { label: 'Date prévisionnelle de fermeture' },
    date_envoi_du_questionnaire: { label: 'Date d\'envoi du questionnaire à l\'AG sortant' },
    date_completude_du_dossier: { label: 'Date de complétude du dossier' },
    date_demande_creation_etablissement_Helium: { label: 'Date d\'enregistrement sous HELIUM' },
    date_demande_immatriculation_GI: { label: 'Date de demande d\'immatriculation GI' },
    date_immatriculation_GI: { label: 'Date d\'immatriculation GI' },
    date_avis_aux_services: { label: 'Date de l\'avis aux services' },
    date_MAJ_ICX: { label: 'Date de mise à jour ICX' },
    date_mise_en_place_contrat_affranchigo: { label: 'Date de mise en place AFFRANCHIGO' },
    date_procuration_postale: { label: 'Date de procuration postale' },
    date_signature_convention_de_courtage: { label: 'Date de signature convention courtage' },
    IBAN_CBC: { label: 'IBAN CBC' },
    date_reprise_gaz: { label: 'Gaz' },
    date_reprise_eau: { label: 'Eau' },
    date_reprise_electricite: { label: 'Electricité' },
    date_reprise_nettoyage: { label: 'Nettoyage' },
    date_reprise_telephone: { label: 'Téléphone' },
    portabilite_telephone: { label: 'Portabilité du numéro de téléphone' },
    conformite_electrique: { label: 'Conformité électrique' },
    conformite_extincteur: { label: 'Conformité extincteur' },
    registre_personnel_recupere: { label: 'Registre du personnel récupéré' },
    date_transmission_registre_personnel: { label: 'Date de transmission du registre du personnel' },
    date_mail_information_personnel_agence: { label: 'Date du mail d\'information au personnel d\'agence' },
    date_envoi_courrier_organismes_sociaux: { label: 'Date envoi courrier organismes sociaux' },
    date_mail_GGvie: { label: 'Date demande GGVIE pour souscription prévoyance' },
    date_paiement_passif_social: { label: 'Date de paiement du passif social' },
    montant_passif_social: { label: 'Montant du passif social' },
    date_signature_bail: { label: 'Date de signature du bail' },
    echeance_de_paiement: { label: 'Echéance de paiement' },
    montant_du_loyer: { label: 'Montant du loyer' },
    montant_de_la_garantie: { label: 'Montant du dépôt de garantie' },
    date_de_fin_de_bail_possible: { label: 'Date de fin de bail possible' },
    type_de_bail: { label: 'Type de bail', options: typeDeBails },
    preavis_impose: { label: 'Préavis imposé' },
    etablissement_ou_transfert_bail: { label: 'Etablissement ou transfert bail' },
    date_changement_des_serrures: { label: 'Date de changement serrures' },
    date_demande_etat_des_lieux: { label: 'Date demande état des lieux' },
    date_realisation_etat_des_lieux: { label: 'Date réalisation état des lieux' },
    date_demande_assurance: { label: 'Date demande assurance' },
    date_reception_du_contrat_assurance: { label: 'Date réception contrat assurance' },
    date_signature_du_contrat_de_location_de_mobilier: { label: 'Date signature contrat location mobilier' },
    date_demande_signaletique: { label: 'Date de demande signalétique' },
    date_etablissment_convention_assuralpes: { label: 'Date convention Assuralpes' },
    date_reception_documents_afferents_au_personnel: { label: 'date_reception_documents_afferents_au_personnel' },
    nom_prenom: { label: 'Nom prénom' },
    date_dernier_entretien: { label: 'Date dernier entretien professionnel' },
    date_envoi_lettre_reprise: { label: 'Date de l\'envoi lettre de reprise' },
    date_derniere_visite_medecine_travail: { label: 'Dernière visite médicale du travail' },
    date_signature_mise_a_disposition: { label: 'Date de signature de mise à disposition' },
    date_demande_GGVIE_souscription_sante: { label: 'Date demande GGVIE pour souscription santé' },
    date_demande_emailing_info_clientele: { label: 'Date demande emailing info clientèle' },
    avenant_contrat_travail_necessaire: { label: 'Avenant au contrat travail nécessaire ?' },
    date_regularisation_avenant: { label: 'Date de régularisation de l\'avenant' },

    // Fermeture Gi
    prestataire: { label: 'Prestataire' },
    date_demande_cloture: { label: 'Date demande de clôture' },
    date_fermeture_gi: { label: 'Date de fermeture de la GI' },
    date_envoi_demande_fermeture: { label: 'Date de demande de fermeture de l\'établissement' },
    date_envoi_avis_au_service: { label: 'Date d\'envoi de l\'avis aux services' },
    date_maj_icx: { label: 'Date de MAJ ICX' },
    date_envoi_mail_de_fin_de_location: { label: 'Date d\'envoi du mail de fin de location' },
    date_traitement_du_bail: { label: 'Date de traitement du bail' },
    date_etat_des_lieux_de_sortie: { label: 'Date d\'état des lieux de sortie' },
    date_demande_resiliation_assurance_du_local: { label: 'Date de demande résiliation assurance local' },
    cession_mobilier_acquis_pendant_la_gi: { label: 'Cession du mobilier acquis pendant la GI' },
    date_resiliation_affranchigo: { label: 'Date de résiliation affranchigo' },
    date_demande_fermeture_du_CBC: { label: 'Date de demande de fermeture du CBC' },
    date_envoi_courrier_de_fin_de_mandat_de_gestion_courtage: { label: 'Date d\'envoi courrier fin de convention de courtage' },
    date_signature_suite_commerciale_telephonie: { label: 'Date de signature' },
    date_envoi_courriers_aux_organismes_sociaux: { label: 'Date envoi courrier organismes sociaux' },
    date_mail_GGvie_pour_changement_de_titulaire_du_contrat_de_prev: { label: 'Date mail à GGvie pour changement titulaire prévoyance' },
    date_envoi_courrier_transfert_contrat_travail: { label: 'Date d\'envoi du courrier de transfert de contrat de travail' },
    date_envoi_mail_fin_location_materiel_mobilier: { label: 'Date d\'envoi du mail de fin de location du matériel et mobilier' },
    date_effective_fermeture_CBC: { label: 'Date effective de fermeture du CBC' },

    // Suivi des procoles
    entity: { label: 'Entité' },
    date_entree_dans_process: { label: 'Date d\'entrée dans le process' },
    date_previsionnelle_sortie_process: { label: 'Date prévisionnelle de sortie du process' },
    type_process: { label: 'Type du process', options: type },
    date_demande: { label: 'Date de la demande' },
    date_realisation: { label: 'Date de réalisation' },
    responsable: { label: 'Acteur / responsable' },
    libelle_demande: { label: 'Libellé de la demande' },
    date_visee: { label: 'Date visée' },

    // Suivi des déménagements
    date_demenagement: { label: 'Date déménagement' },
    date_demenagement_souhaitee: { label: 'Date déménagement souhaitée' },
    motifs: { label: 'Motifs' },
    date_reception_demande: { label: 'Date de reception de la demande' },
    date_demande_mise_conformite: { label: 'Date de demande de mise en conformité' },
    date_demande_etude_atlas: { label: 'Date de demande' },
    date_reception_etude_atlas: { label: 'Date de reception' },
    date_reception_etude_atlas_full: { label: 'Date de reception Atlas' },
    conclusion_etude: { label: 'Conclusion de l\'étude' },
    date_communication_decision_agent: { label: 'Entité' },
    proposition_validee: { label: 'Proposition validée' },
    date_proposition_validee: { label: 'Entité' },
    date_decision_communiquee: { label: 'Date de décision communiquée' },
    pdv: { label: 'PDV' },

    // Suivi de dossier signalétique
    origine_du_besoin: { label: 'Origine du besoin', options: origineBesoin },
    date_envoi_du_formulaire_au_prestataire: { label: 'Date d\'envoi du formulaire au prestataire' },
    date_reception_du_survey: { label: 'Date de réception du survey' },
    date_validation_du_survey_par_agent: { label: 'Date de valdation du survey par l\'agent' },
    date_demande_autorisation_mairie: { label: 'Date de demande d\'autorisation mairie' },
    date_autorisation_mairie: { label: 'Date d\'autorisation mairie' },
    date_mise_en_fabrication_par_le_prestataire: { label: 'Date de mise en fabrication par le prestataire' },
    date_de_pose: { label: 'Date de pose' },
    date_reception: { label: 'Date de réception' },
    date_reception_facture: { label: 'Date de réception facture' },
    montant_de_la_facture_part_GAN: { label: 'Montant de la facture part GAN' },
    date_reglement_part_GAN: { label: 'Date d\'envoi facture à la comptabilité' },
    montant_facture_part_agent: { label: 'Montant de la facture part agent' },
    date_reglement_de_la_part_agent: { label: 'Date de règlement de la part agent' },
    besoin: { label: 'Besoin' },

    // Suivi des aides
    type_aide_id: { label: 'Type d\'aide', options: typeAideOptions },
    millesime_id: { label: 'Millésime' },
    enveloppe_disponnible: { label: 'Enveloppe disponible' },
    montant_aide: { label: 'Montant de l\'aide' },
    date_attribution: { label: 'Date d\'attribution' },
    montant_regle: { label: 'Montant réglé' },
    reste_a_regler: { label: 'Reste à régler' },

    // Suivi des aides admin
    type_aide: { label: 'Type d\'aide', options: typeAideOptions },
    millesime: { label: 'Millésime' },
    region_id: { label: 'Region', options: regions },
    region: { label: 'Region', options: regions },
    montant_enveloppe: { label: 'Montant de l\'enveloppe' },
    date_affectation: { label: 'Date d\'affectation' },

    // Console administration
    nom_stage: { label: 'Nom du stage' },
    type_objectif: { label: 'Type d\'objectif' },
    millesime_objectif: { label: 'Millésime d\'objectif' },
    type_objectif_par_millesime: { label: 'Type d\'objectif par millésime' },
    valeur: { label: 'Valeur' }
  },
  filters: {
    date_cible: { label: 'Date cible' },
    region_id: { label: 'Région', options: regions },
    publication_annonce: { label: 'Publication annonce' },
    statut: { label: 'État', options: status },
    date_nomination: { label: 'Date de nomination' },
    candidat: { label: 'Candidat' },
    millesime_nomination: { label: 'Millésime de nomination' },
    agent: { label: 'Agent' },
    decision_dir_comm: { label: 'Titularisation', options: titularisations },
    millesime_fin_periode_probatoire: { label: 'Millésime de fin de période probatoire' },
    nature: { label: 'Nature' },
    date: { label: 'Date' },
    portefeuille: { label: 'Portefeuille' },
    millesime: { label: 'Millésime' },
    motif: { label: 'Motif de départ', options: motifDeparts },
    type_transfert: { label: 'Type de transfert' },
    millesime_ouverture: { label: 'Millésime d\'ouverture' },
    millesime_fermeture: { label: 'Millésime de fermeture' },
    type: { label: 'Type', options: type },
    millesime_entree: { label: 'Millésime d\'entrée' },
    millesime_sortie: { label: 'Millésime de sortie' },
    type_process: { label: 'Type', options: type },
    entite: { label: 'Entité' },
    millesime_date_demenagement: { label: 'Millésime date déménagement' },
    millesime_etude_atlas: { label: 'Millésime étude Atlas' },
    origine_du_besoin: { label: 'Origine du besoin', options: origineBesoin },
    millesime_date_pose: { label: 'Millésime date de pose' },
    millesime_date_effet: { label: 'Millésime date d\'effet' },
    type_aide_id: { label: 'Type d\'aide', options: typeAideOptions },
    millesime_id: { label: 'Millesime' }
  },
  delete_process_confirm: 'Êtes-vous sûr de vouloir supprimer ce process ?',
  delete_success: 'Process supprimé',
  save_success: 'Process sauvegardé',
  required_value: 'Veuillez remplir tous les champs',

  add: 'Ajouter',
  add_candidat: 'Ajouter un candidat',
  add_process_on_required: 'Si ce recrutement est lié à un départ. Veuiller ajouter le process correspondant plus bas',
  add_pftConfie: 'Ajouter un portefeuille confié',
  add_agent: 'Ajouter un agent',
  add_montant: 'Ajouter un montant',
  add_reglement: 'Ajouter un règlement',
  add_entity: 'Ajouter une entité',

  suivi_de_periode_probatoire: 'Suivi de période probatoire',
  suivi_du_mandat: 'Suivi du mandat',
  suivi_des_ic: 'Suivi ic',
  suivi_de_depart: 'Suivi de départ',
  suivi_de_recrutement: 'Suivi de recrutement',
  suivi_signaletique: 'Suivi signalétique',
  ouverture_gi: 'Ouverture GI',

  create_process: 'Créer un process',
  create_suivi: 'Créer un suivi',
  create_suivi_de_recrutement: 'Créer un suivi de recrutement',
  create_suivi_de_nomination: 'Créer un suivi de nomination',
  create_suivi_de_periode_probatoire: 'Créer un suivi de période probatoire',
  create_suivi_des_ic: 'Créer un suivi des ic',
  create_suivi_des_departs: 'Créer un suivi des départs',
  create_suivi_de_mandat_gestion: 'Créer un suivi de mandat de gestion',
  create_suivi_des_delegations: 'Créer un suivi de délégation',
  create_suivi_de_transfert_portefeuille: 'Créer un suivi de transfert de PTF',
  create_suivi_de_dossier_signaletique: 'Créer un suivi de dossier signalétique',
  create_suivi_des_aides: 'Créer une aide',
  create_ouverture_gi: 'Créer une ouverture de GI',
  create_fermeture_gi: 'Créer une fermeture de GI',
  create_suivi_des_demenagements: 'Créer un suivi de déménagement',
  create_sujet_commit: 'Créer un sujet de comité',
  create_linked_suivi_des_ic: 'Créer les suivis des IC',
  create_linked_suivi_de_recrutement: 'Créer un suivi de recrutement lié',
  create_task: 'Créer une tâche',

  copy_annonce: 'Copier l\'annonce',
  copy_avis: 'Copier l\'avis au',
  copy_message: 'Copier le message',
  copy_demande: 'Copier la demande',
  copy_mandat: 'Copier le mandat',
  copy_info: 'Copier les informations traité de nomination',
  copy_cour: 'Copier le courrier',
  copy_prepa: 'Copier préparation',
  copy_questionnaire: 'Copier le questionnaire',
  copy_mail: 'Copier le mail',
  copy_avisService: 'Copier l\'avis au service',

  linked_entity: 'Entité liée',
  linked_entities: 'Entité(s) lié(s)',
  linked_wallets: 'Portefeuille(s) lié(s)',
  linked_pdv_wallets: 'Portefeuille(s) PDV lié(s)',
  linked_entity_or_pdv_wallets: 'Entité(s) ou PDV liée(s)',
  linked_wallet: 'Portefeuille lié',
  linked_processes: 'Process lié(s)',
  linked_suivi_de_periode_probatoire: 'Suivi des période(s) probatoire liée(s)',
  linked_suivi_des_ic: 'Suivi des ic lié(s)',
  linked_suivi_de_recrutement: 'Suivi de recrutement lié',
  linked_suivi_signaletique: 'Suivi signalétique lié',

  link_wallet: 'Associer un portefeuille',
  link_entity: 'Associer une entité',
  link_process: 'Associer un process',
  link_entity_or_pdv_wallet: 'Ajouter une entité ou un PDV',

  unlink_related_object: 'Détacher l\'objet lié',
  unlink_recrutement: 'Détacher le process',
  unlink_suivi_ic: 'Détacher le process',
  unlink_depart: 'Détacher le process',
  unlink_suivi_signatetique: 'Détacher le process',

  save: 'Sauvegarder',
  enregistrer: 'Enregistrer',
  export: 'Exporter',
  export_candidat: 'Exporter les candidats',
  export_portefeuilleConfie: 'Exporter les portefeuilles confiés',
  export_salarie: 'Exporter les salariés',
  export_clotureAbonnement: 'Exporter les clotures abonnements',
  export_echeance: 'Exporter les echeances',
  export_prorogation: 'Exporter les prorogations',
  export_enveloppe: 'Exporter les enveloppes',
  export_typeAide: 'Exporter les types d\'aides',
  export_millesime: 'Exporter les millesimes',
  export_reglement: 'Exporter les reglements',
  export_montant: 'Exporter les montants',
  export_action: 'Exporter les actions',

  annonce: 'Annonce',
  informations_candidat: 'Informations candidat',
  candidat: 'Candidat(s)',
  validation_state: 'état de validation',
  nomination: 'Nomination',
  links: 'lien(s)',
  objectifs: 'Objectif(s)',
  portefeuillesConfies: 'Portefeuille(s) confié(s)',
  portefeuille: 'Portefeuille',
  periodeProbatoire: 'Période probatoire',
  success: 'Succès',
  processAgence: 'Process agence',
  agent: 'Agent',
  entity: 'Entité',
  periodeProbatoireExplanation: 'à l\'enregistrement, un sujet pour chaque bilant seront générés dans la section comités régionaux',
  avisDeLaRegion: 'Avis de la région',
  siege: 'Siège',

  bilan3mois: ' Bilan 3 mois',
  bilan6mois: ' Bilan 6 mois',
  bilan9mois: ' Bilan 9 mois',
  bilan12mois: ' Bilan 12 mois',
  bilan18mois: ' Bilan 18 mois',

  sujetsLies: 'Sujet(s) lié(s)',
  wallet: 'Portefeuille',
  iardSante: 'Iard & Santé',
  icVie: 'Ic Vie',
  iard: 'Iard',
  sante: 'Santé',
  vie: 'Vie',
  differentielIc: 'Différentiel IC',
  sinistreDeReorganisation: 'Sinistre de ré-organisation',
  montant_iard: 'Montant IARD',
  montant_sante: 'Montant Santé',
  ic_vie: 'Ic vie',
  montant_ic_vie: 'Montant Ic Vie',
  differentiel_ic: 'Différentiel IC',
  montant_differentiel_ic: 'Montant différentiel Ic',
  sinistre_reorganisation: 'Sinistre réorganisation',
  montant_sinistre_reorganisation: 'Montant sinistre réorganisation',
  synthese: 'Synthèse',
  echeancierPartClassique: 'Echéancier part classique',
  reglementPartClassique: 'Règlement part classique',
  echeancierPartComplementaire: 'Echéancier part complémentaire',
  reglementPartComplementaire: 'Règlement part complémentaire',
  reglement: 'Règlement',
  initialize_process_wallet: 'Initialiser le processus d\'analyse du portefeuille',
  date_analyse_portefeuille: 'Date d\'analyse du portefeuille - points majeurs',
  dossierDestinationTransmission: 'Dossier destination transmission',
  motifFinaliteMandat: 'Motif et finalité de mandat',
  donneesChiffrees: 'Données chiffrées',
  agentEntiteLie: 'Agent ou entité lié',
  prorogationAdded: 'Prorogation ajoutée',
  echeancePaiement: 'Echéances et paiements',
  echeancePaiementAdded: 'Echéance et paiement ajoutés',
  banqueDeleguee: 'Banque déléguée',
  courrierAcceptation: 'Courrier d\'acceptation',
  dateMainLevee: 'Date de main levée',
  objetReseau: 'Objet(s) du réseau',
  origine_wallet: 'Portefeuille origine',
  cible_wallet: 'Portefeuille cible',
  transfert: 'Transfert',
  listContrat: 'Liste de contrats',
  dossier: 'Dossier',
  dateRepriseEffectiveDesAbos: 'Dates de reprise effective des abonnements',
  salaries: 'Salariés',
  bail: 'Bail',
  localEtMobilier: 'Local et mobilier',
  locauxEtImmobilier: 'Locaux et immobilier',
  personnel: 'Personnel',
  decision: 'Décision',
  // Suivi des ic
  formatInvalid: 'Format échéance invalide',

  // Fermeture Gi
  localEtImmobilier: 'Local et immobilier',
  bailLocalMobilier: 'Bail/Local/Mobilier',
  dateResiliationDesAbos: 'Date de résiliation des abonnements',
  suiteCommercialeTelephone: 'Suite commerciale téléphone',
  clotureAbos: 'Cloture des abonnements',
  clotureAbos_added: 'Ajouter',

  // Suivi des protocoles
  informations: 'Informations',
  planAction: 'Plan d\'actions',
  planAction_added: 'Ajouter',

  // Suivi des déménagements
  demandeEtudeAtlas: 'Demande d\'étude ATLAS',

  // Suivi des aides
  etatDesAides: 'Etat des aides',
  montantEngage: 'Montant engagé',
  reglements: 'Règlement(s)',
  MONTANT_AIDE_TOO_HIGH: 'Enveloppe budgétaire insuffisante',
  NO_AIDE_ADMIN_FOUND: 'Aide Admin associée aux paramètres inexistante',

  // Suivi des aides admins
  enveloppeBudgetaire: 'Enveloppe(s) budgétaire(s)',
  add_enveloppe: 'Ajouter',

  // Console administration
  stage_process_suivi_de_recrutement: 'Stage process Suivi de recrutement',
  objectif_process_suivi_de_nomination: 'Objectifs process Suivi de nomination',
  add_stage: 'Ajouter un stage',
  add_objectif: 'Ajouter un objectif'
}
