export default {
  login: 'Identification',
  username: 'Identifiant',
  email: 'Adresse E-mail',
  password: 'Mot de passe',
  connect: 'Se connecter',
  wrong_credentials: 'Identidiants et/ou mot de passe érronés',
  login_success: 'Connexion réussie',
  logout: 'Déconnexion',
  logout_success: 'Vous êtes maintenant déconnecté',
  lost_password: 'Mot de passe oublié',
  lost_password_instructions: 'Saisissez votre adresse e-mail pour recevoir un lien de réinitialisation de votre mot de passe.',
  reset_password: 'Mot de passe oublié',
  reset_password_instructions: 'Saisissez votre nouveau mot de passe',
  new_password: 'Mot de passe',
  password_confirmation: 'Confirmation du mot de passe',
  recover_password: 'Envoyer',
  recovery_email_sent: 'Cliquez le le lien présent dans l\'email qui vous a été envoyé...',
  back_to_login_page: 'Retour à la page de connexion',
  password_reset_success: 'Mot de passe mis à jour',
  password_too_short: 'Mot de passe trop court',
  password_not_matching: 'Les mots de passe ne correspondent pas',

  errors: {
    UNAUTHORIZED: 'Vos identifiants ou mot de passe sont incorrectes. Si vous ne parvenez pas à vous connecter, veuillez contacter l\'administrateur du site.'
  }
}
