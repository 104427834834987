import axios from 'axios'

export function fetchProcesses ({ commit, getters, rootState }, params) {
  let { typeProcess, filter } = params
  delete params.rowsNumber
  delete params.typeProcess
  delete params.filter

  const currentUserRegionId = rootState.auth.currentUser.region_id

  let url = `/api/processAgences/${typeProcess}`
  if (filter === 'archive') url = `${url}/archives`
  else if (filter === 'en-cours') url = `${url}/enCours`

  return axios.get(url, { params })
    .then(response => response.data)
    .then(result => {
      let data = result.data ? result.data : result
      let processes = currentUserRegionId === 6 ? data : data.filter(process => process.region_id === currentUserRegionId)
      commit('setProcesses', processes)

      if (result.data) {
        commit('setPagination', Object.assign({}, getters['getPagination'], {
          page: result.current_page,
          rowsPerPage: result.per_page,
          rowsNumber: result.total
        }))
      }
    })
}

export function fetchFormFields ({ commit }, params) {
  let type = typeof params === 'object' ? params.type : params
  let model_id = typeof params === 'object' ? params.model_id : null
  let droits_title = typeof params === 'object' ? params.droits_title : null
  return axios.get('/api/config/forms', { params: { model: type, model_id, droits_title } })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function fetchFilters ({ commit }, type) {
  return axios.get('/api/config/filters', { params: { model: type } })
    .then(response => response.data)
    .then(result => { commit('setFilters', result) })
}

export function fetchProcess ({ commit }, params = {}) {
  return axios.get(`/api/processAgences/${params.type}/${params.id}`)
    .then(response => response.data)
    .then(process => { commit('setCurrentProcess', process) })
}

export function saveProcesses ({ commit }, params = {}) {
  const { id, model_type } = params
  delete params.model_type
  delete params.classique
  delete params.complementaire

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/processAgences/${model_type}/${id}` : `/api/processAgences/${model_type}/`
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function deleteProcesses ({ commit }, params) {
  return axios.delete(`/api/processAgences/${params.type}/${params.id}`)
    .then(response => response.data)
}

export function addMontantPropose ({ commit }, params) {
  const { id } = params
  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/processAgences/montantPropose/${id}` : `/api/processAgences/montantPropose/`
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function saveEcheanceRegleCustom ({ commit }, params) {
  const { suiviIcId } = params
  const apiVerb = 'patch'
  const apiRoute = `/api/processAgences/suiviIc/${suiviIcId}/echeances/reglesCustom`

  delete params.suiviIcId
  const data = params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function removeMontantPropose ({ commit }, id) {
  return axios.delete(`/api/processAgences/montantPropose/${id}`)
    .then(response => response.data)
}

export function addReglement ({ commit }, params) {
  const { id } = params
  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/processAgences/paiement/${id}` : `/api/processAgences/paiement/`
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function removeReglement ({ commit }, id) {
  return axios.delete(`/api/processAgences/paiement/${id}`)
    .then(response => response.data)
}

export function fetchEcheance ({ commit }, params) {
  return axios.get(`/api/processAgences/suiviIc/${params.suiviDesIcId}/echeances`)
    .then(response => response.data)
    .then(process => { commit('setCurrentProcess', process) })
}

export function addProrogation ({ commit }, params) {
  const { id } = params
  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/processAgences/prorogation/${id}` : `/api/processAgences/prorogation/`

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data: params
  }).then(response => response.data)
}

export function addEcheancePaiement ({ commit }, params) {
  const { id } = params
  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/processAgences/echeancePaiement/${id}` : `/api/processAgences/echeancePaiement/`

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data: params
  }).then(response => response.data)
}

export function createLinkedProcess ({ commit }, params) {
  const { linkedProcess_type, process_id, process } = params
  const apiRoute = `/api/processAgences/${process}/${process_id}/create/${linkedProcess_type}`

  return axios.request({
    method: 'get',
    url: apiRoute,
    data: {}
  }).then(response => response.data)
}

export function getEnveloppeDisponibleAide ({ commit }, params) {
  return axios.get('/api/processAgences/aide/enveloppe', { params })
    .then(response => response.data)
}
