<template>
  <q-card flat class="items-end">
    <q-card-section class="q-pa-none full-height">
      <q-item class="q-pa-none full-height">
        <q-card-section v-if="draggable" class="column q-pa-sm justify-center bg-grey-4 handle" :style="{ cursor: 'move' }">
          <q-icon name="drag_handle" color="white" size="1rem" />
        </q-card-section>

        <q-item-section avatar class="q-pa-md self-start" v-if="hasSlot('avatar')">
          <slot name="avatar" />
        </q-item-section>

        <q-item-section class="q-pa-md self-start">
          <slot />
        </q-item-section>

        <q-item-section side top v-if="hasSlot('indicators')" class="q-ma-md column">
          <slot name="indicators" />
        </q-item-section>

        <q-separator v-if="showSeparator && actions" vertical class=""/>

        <q-item-section side top class="q-py-md q-mr-md justify-between">
          <q-btn flat dense size="sm" icon="more_vert" v-if="actions && actions.length > 0">
            <q-menu auto-close>
              <q-list>
                <q-item v-for="action in actions" v-bind:key="action.label"
                  @click="$emit('menuClick', action.payload)" clickable>
                  <q-item-section>{{action.label}}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <slot name="actions" />

          <q-btn v-if="fav_candidate" flat dense size="sm" :icon="is_favorite ? 'star' : 'star_border'" @click="toggleFavorite" />
        </q-item-section>
      </q-item>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'base-card',
  props: {
    'draggable': { type: Boolean, default: false },
    'actions': { type: Array },
    'fav_candidate': { type: Boolean, default: false },
    'is_favorite': { type: Boolean, default: false },
    'deletable': { type: Boolean, default: false },
    'model': { type: Object },
    'showSeparator': { type: Boolean, default: true }
  },

  methods: {
    hasSlot (name = 'default') {
      return !!this.$slots[name]
    },

    toggleFavorite () {
      const { model_type, model_id } = this.model

      if (!this.is_favorite) {
        this.$store.dispatch('favorites/saveFavorite', { model_type, model_id })
          .then(() => { this.$q.notify({ message: this.$t(`favorites.save_success`), color: 'positive' }) })
          .then(() => { this.$store.dispatch('config/fetchCounts') })
      } else {
        this.$store.dispatch('favorites/deleteFavorite', { model_type, model_id })
          .then(() => { this.$q.notify({ message: this.$t(`favorites.delete_success`), color: 'positive' }) })
          .then(() => { this.$store.dispatch('config/fetchCounts') })
      }
    }
  }
}
</script>

<style>
</style>
