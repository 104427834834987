export function getInstances (state) {
  return state.instances
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrentInstance (state) {
  return state.currentInstance
}
