export function setSujets (state, sujets) {
  state.sujets = sujets
}

export function setFormFields (state, formFields) {
  state.formFields = formFields
}

export function setFilters (state, filters) {
  state.filters = filters ? filters.map(filter => ({
    ...filter, value: filter.value ? filter.value : null
  })) : null
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setCurrentSujet (state, sujet) {
  state.currentSujet = sujet
}
