export function setEntities (state, entities) {
  state.entities = entities
}

export function setMapEntities (state, mapEntities) {
  state.mapEntities = mapEntities
}

export function setFormFields (state, formFields) {
  state.formFields = formFields
}

export function setFilters (state, filters) {
  state.filters = filters.map(filter => ({
    ...filter, value: filter.value ? filter.value : null
  }))
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setCurrentEntity (state, entity) {
  state.currentEntity = entity
}

export function setCibles (state, cibles) {
  state.cibles = cibles
}

export function setSegmentOptions (state, segmentOptions) {
  state.segmentOptions = segmentOptions
}
