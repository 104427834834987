import auth from './auth'
import roles from './roles'
import regions from './regions'
import users from './users'
import entities from './entities'
import statuts from './statuts'
import qualifications from './qualifications'
import sousCibles from './sousCibles'
import cibles from './cibles'
import tasks from './tasks'
import notes from './notes'
import instances from './instances'
import sujets from './sujets'
import notifications from './notifications'
import processes from './processes'
import candidat from './candidat'
import ptfConfie from './ptfConfie'

const sources = {
  COMITE_REGIONAL: 'Comité régional',
  REUNION_DG: 'Réunion DG',
  MAIL: 'Mail',
  REUNION_PER_REGIONALE: 'Réunion PER régionale',
  OUTIL_ADMINISTRATION_AGENCES: 'Outil Administration des Agences',
  SUIVI_PER: 'Suivi PER',
  AUTRE: 'Autre'
}

export default {
  auth,
  roles,
  regions,
  users,
  entities,
  statuts,
  qualifications,
  sousCibles,
  cibles,
  tasks,
  notes,
  instances,
  sujets,
  notifications,
  processes,
  candidat,
  ptfConfie,

  global: {
    'site-name': 'Agora',
    home: 'Accueil',
    admin: 'Admin',
    entities: 'Réseau',
    per: {
      per: 'PER',
      gestion: 'Gestion des cibles',
      dashboard: 'Météo des cibles',
      comptage: 'Comptage réseau'
    },
    tasks: 'Tâches',
    comite: 'Comités Région',
    divergence: 'Divergences',
    process: 'process',
    back: 'Retour'
  },

  per: {
    dashboard: {
      comptage_reseau: {
        title: 'État réseau actuel',
        entites: 'Entités',
        agents: 'Agents',
        wallets: 'Portefeuilles (code ICX)',
        point_de_vente: 'Points de ventes',
        mandats_gestion: 'Mandats de gestion'
      },
      cibles_EN_COURS: {
        title: 'Météo des cibles en cours',
        cibles: 'Cibles en cours'
      },
      cibles_TERMINE: {
        title: 'Météo des cibles terminées',
        cibles: 'Cibles terminées'
      },
      cibles_ABANDONNE: {
        title: 'Météo des cibles abandonnées',
        cibles: 'Cibles abandonnées'
      },
      sous_cibles_EN_COURS: {
        title: 'Météo des sous-cibles en cours',
        cibles: 'Sous-cibles en cours'
      },
      sous_cibles_TERMINE: {
        title: 'Météo des sous-cibles terminées',
        cibles: 'Sous-cibles terminées'
      },
      sous_cibles_ABANDONNE: {
        title: 'Météo des sous-cibles abandonnées',
        cibles: 'Sous-cibles abandonnées'
      },
      entities: {
        entities: 'Entité',
        CREATION_ENTITE: 'Création d\'une entité',
        CREATION_POSTE: 'Création d\'un poste Agent',
        SUPPRESSION_POSTE: 'Suppression d\'un poste Agent',
        EMBAUCHE_COMMERCIAL: 'Embauche d\'un commercial',
        RECODIFICATION_PORTEFEUILLE: 'Recodification d\'un portefeuille'
      },
      agents: {
        agents: 'Agent',
        NOMINATION: 'Nomination',
        CESSE_FONCTIONS: 'Cesse ses fonctions',
        CHANGE_ENTITE: 'Change d\'entité'
      },
      wallets: {
        wallets: 'Portefeuille',
        CHANGEMENT_TITULAIRE: 'Changement de titulaire',
        CREATION_PORTEFEUILLE: 'Création d\'un portefeuille',
        CLOTURE_PORTEFEUILLE: 'Cloture de portefeuille',
        TRANSFERTS_PORTEFEUILLE: 'Transferts'
      },
      pdvs: {
        pdvs: 'Point de vente',
        OUVERTURE_PDV: 'Ouverture d\'un point de vente',
        FERMETURE_PDV: 'Fermeture d\'un point de vente',
        DEPLACEMENT_PDV: 'Déplacement de point de vente'
      }
    },

    comptage: {
      agents: { label: 'Agents' },
      comm_inf_180k: { label: 'Comm < 180k' },
      date_creation: { label: 'date_creation' },
      date_projetee: { label: 'date_projetee' },
      embauches_commerciaux: { label: 'Embauches de Commerciaux' },
      entites: { label: 'Entités' },
      entites_mono_agent: { label: 'Mono-agent' },
      entites_multi_agents: { label: 'Multi-agents (asso)' },
      gi: { label: 'GI' },
      mandats_gestion: { label: 'Nbr de mandats de gestion' },
      point_de_vente: { label: 'Nbr de points de vente' },
      postes_ag: { label: 'Poste' },
      potentiel_agent: { label: 'Potentiel agent' },
      recodifications: { label: 'Recodif AG seul' },
      sarl: { label: 'SARL' },
      wallets: { label: 'Portefeuilles' }
    }
  },

  dashboard: {
    filters: {
      region_id: { label: 'Région', options: regions }
    }
  },

  agents: {
    agents: 'Agent(s)',
    agent: 'Agent',
    years: 'ans',
    date_agent: 'Agent depuis'
  },

  wallets: {
    wallets: 'Portefeuille(s)',
    wallet: 'Portefeuille',
    NOMPTF: 'Nom du portefeuille',
    nom_du_pdf: 'Nom du PTF',
    TYPE_RGT: 'PDV',
    VILLE: 'Ville',
    mdt_gest: 'Mandat gestion (%)',
    comm_totale: 'Comm.',
    comm_ajustee: 'Comm. ajustée',
    total_commissions: 'Total commission PTF (N-1)'
  },

  comments: {
    comments: 'Commentaires',
    comment: 'Commentaire',
    fields: {
      contenu: { label: 'Contenu' }
    },
    add_comment: 'Ajouter un commentaire',
    delete_comment: 'Supprimer',
    delete_comment_confirm: 'Êtes-vous sûr de vouloir supprimer ce commentaire ?',
    save_success: 'Commentaire sauvegardé',
    delete_success: 'Commentaire supprimé'
  },

  evenements: {
    evenements: 'Évenements',
    evenement: 'Évenement',
    add_evenement: 'Ajouter un événement',
    edit_evenement: 'Modifier l\'événement',
    delete_evenement: 'Supprimer cet événement',
    delete_evenement_confirm: 'Êtes-vous sûr de vouloir supprimer cet événement ?',
    fields: {
      label: { label: 'Sujet de l\'événement' },
      description: { label: 'Description' },
      source: { label: 'Source', options: sources },
      impact_cible: { label: 'Impactant' },
      date: { label: 'Date' }
    },
    filters: {
      source: { label: 'Source', options: sources },
      impact_cible: { label: 'Impact cible' },
      date_start: { label: 'Après le' },
      date_end: { label: 'Avant le' }
    },
    save_success: 'Évenement sauvegardé',
    delete_success: 'Évenement supprimé'
  },

  favorites: {
    no_favorites: 'Aucun favori',
    save_success: 'Favori sauvegardé',
    delete_success: 'Favori supprimé'
  },

  form: {
    missing_fields: 'Veuillez remplir les champs obligatoires',
    required_field: 'Ce champ est obligatoire',
    invalid_email: 'Email invalide',
    invalid_date: 'Date invalide',
    send: 'Envoyer',
    save: 'Sauvegarder',
    ok: 'ok',
    submit: 'Enregistrer',
    cancel: 'Annuler',
    finish: 'Terminer',
    delete: 'Supprimer',
    create: 'Créer'
  },

  date: 'Date',
  created_at: 'Créé le',
  updated_at: 'Modifié le',
  delete: 'Supprimer'
}
