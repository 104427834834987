import axios from 'axios'

export function fetchUsers ({ commit, getters }, params = {}) {
  delete params.rowsNumber

  return axios.get('/api/users', { params })
    .then(response => response.data)
    .then(result => {
      commit('setUsers', result.data)
      commit('setPagination', Object.assign({}, getters['getPagination'], {
        page: result.current_page,
        rowsPerPage: result.per_page,
        rowsNumber: result.total
      }))
    })
}

export function fetchFormFields ({ commit }, params = {}) {
  params.model = 'user'
  return axios.get('/api/config/forms', { params })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function fetchFilters ({ commit }) {
  return axios.get('/api/config/filters', { params: { model: 'user' } })
    .then(response => response.data)
    .then(result => { commit('setFilters', result) })
}

export function getUser ({ commit }, id) {
  return axios.get(`/api/users/${id}`)
    .then(response => response.data)
    .then(user => { commit('setCurrentUser', user) })
}

export function saveUser ({ commit }, params) {
  const { id } = params
  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/users/${id}` : '/api/users/'
  const data = params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function deleteUser ({ commit }, id) {
  return axios.delete(`/api/users/${id}`)
    .then(response => response.data)
}
