export function getEntities (state) {
  return state.entities
}

export function getMapEntities (state) {
  return state.mapEntities
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrentEntity (state) {
  return state.currentEntity
}

export function getCibles (state) {
  return state.cibles
}

export function getSegmentOptions (state) {
  return state.segmentOptions
}
