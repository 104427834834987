export default {
  mainMenuItems: [
    { label: 'global.home', route: { name: 'entities-list' } },
    { label: 'global.entities', route: { name: 'entities-list' } },
    { label: 'global.comite', route: { name: 'instances-list' } },
    { label: 'global.per.per',
      children: [
        { label: 'global.per.gestion', route: { name: 'cibles-list' } },
        { label: 'global.per.dashboard', route: { name: 'dashboard-per' } },
        { label: 'global.per.comptage', route: { name: 'comptage-reseau' } }
      ] },
    { label: 'global.tasks', route: { name: 'tasks-list' } },
    { label: 'global.divergence', route: { name: 'divergence' } },
    { label: 'users.management', route: { name: 'users-list' } }
  ],
  counts: null,
  rowsPerPageOptions: [5, 10, 20, 50]
}
