import status from './statuts'

const type = {
  IC: 'IC',
  GRE_A_GRE: 'Gré à gré'
}

export default {
  fields: {
    statut: { label: 'État', options: status },
    taux_detention: { label: 'Taux de détention' },
    type_reorganisation: { label: 'Type de réorg', options: type },
    libelle_entite_agent: { label: 'Libellé pour entité agent' },
    nom_adresse_expert_incendie: { label: 'Nom & adresse expert incendie' },
    date_demande_listing_IC_IA: { label: 'Date de demande de listing IC IA' },
    date_demande_calcul_IC_VIE: { label: 'Date de demande de calcul IC VIE' },
    date_reception_formulaire_CBC: { label: 'Date de reception du formulaire CBC' },
    date_reception_RIB_CBC: { label: 'Date de reception du RIB CBC' },
    date_reception_RIB_compte_frais_generaux: { label: 'Date de reception du RIB compte frais généraux' },
    date_demande_mandat_SFPJ: { label: 'Date de mandat SFPJ' },
    date_signature_mandat_SFPJ: { label: 'Date de signature' },
    date_emission_avis_service: { label: 'Date de l\'émission de l\'avis au service' },
    date_demande_abilitations_informatiques: { label: 'Date de demande d\'habilitations informatiques' },
    nom: { label: 'Nom' }
  },
  IC: 'IC',
  formulaire_cbc: 'Formulaire CBC',
  mandat_sfpj: 'Mandats SFPJ',
  avis_au_services: 'Avis au services',
  habilitation_info: 'Habilitations informatiques'
}
