export default {
  formFields: null,
  filters: [],
  filterValues: {},
  pagination: {
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 0,
    rowsNumber: 0
  },
  notes: [],
  currentNote: null
}
