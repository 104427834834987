import axios from 'axios'

export function fetchDashboard ({ commit, getters }, params = {}) {
  delete params.rowsNumber

  return axios.get('/api/dashboard-per', { params })
    .then(response => response.data)
    .then(results => {
      const getCount = ({ label, counts }) => {
        return { label, counts: results[label] || { total: 0, bien: 0, moyen: 0, mal: 0 } }
      }

      const comptage = {
        entities: [
          { label: 'CREATION_ENTITE', counts: {} },
          { label: 'CREATION_POSTE', counts: {} },
          { label: 'SUPPRESSION_POSTE', counts: {} },
          { label: 'EMBAUCHE_COMMERCIAL', counts: {} },
          { label: 'RECODIFICATION_PORTEFEUILLE', counts: {} }
        ],
        agents: [
          { label: 'NOMINATION', counts: {} },
          { label: 'CESSE_FONCTIONS', counts: {} },
          { label: 'CHANGE_ENTITE', counts: {} }
        ],
        wallets: [
          { label: 'CHANGEMENT_TITULAIRE', counts: {} },
          { label: 'CREATION_PORTEFEUILLE', counts: {} },
          { label: 'CLOTURE_PORTEFEUILLE', counts: {} }
        ],
        pdvs: [
          { label: 'OUVERTURE_PDV', counts: {} },
          { label: 'FERMETURE_PDV', counts: {} },
          { label: 'DEPLACEMENT_PDV', counts: {} }
        ]
      }

      commit('setDashboard', {
        ...results,
        comptage: {
          entities: comptage.entities.map(getCount),
          agents: comptage.agents.map(getCount),
          wallets: comptage.wallets.map(getCount),
          pdvs: comptage.pdvs.map(getCount)
        }
      })
    })
}

export function fetchFilters ({ commit }) {
  return axios.get('/api/config/filters', { params: { model: 'dashboard' } })
    .then(response => response.data)
    .then(result => { commit('setFilters', result) })
}
