import axios from 'axios'

export function fetchEvenements ({ commit, getters }, params = {}) {
  delete params.rowsNumber

  return axios.get('/api/evenements', { params })
    .then(response => response.data)
    .then(result => {
      if (result.data) {
        commit('setEvenements', result.data)
        commit('setPagination', Object.assign({}, getters['getPagination'], {
          page: result.current_page,
          rowsPerPage: result.per_page,
          rowsNumber: result.total
        }))
      } else {
        result.forEach(event => {
          if (event.lien_type != null && event.lien_id != null) event.link = `/comite/${event.lien_type}/${event.lien_id}`
          else event.link = null
        })
        commit('setEvenements', result)
      }
    })
}

export function fetchFormFields ({ commit }) {
  return axios.get('/api/config/forms', { params: { model: 'evenement' } })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function fetchFilters ({ commit }) {
  return axios.get('/api/config/filters', { params: { model: 'evenement' } })
    .then(response => response.data)
    .then(result => { commit('setFilters', result) })
}

export function getEvenement ({ commit }, id) {
  return axios.get(`/api/evenements/${id}`)
    .then(response => response.data)
    .then(evenement => { commit('setCurrentEvenement', evenement) })
}

export function saveEvenement ({ commit }, params = {}) {
  const { id } = params

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/evenements/${id}` : '/api/evenements/'
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  }).then(response => response.data)
}

export function deleteEvenement ({ commit }, id) {
  return axios.delete(`/api/evenements/${id}`)
    .then(response => response.data)
}
