export function getComments (state) {
  return state.comments
}

export function getFormFields (state) {
  return state.formFields
}

export function getCurrent (state) {
  return state.current
}
