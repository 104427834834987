import { render, staticRenderFns } from "./base-input-date.vue?vue&type=template&id=da971822&scoped=true&"
import script from "./base-input-date.vue?vue&type=script&lang=js&"
export * from "./base-input-date.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da971822",
  null
  
)

export default component.exports