import axios from 'axios'

export function fetchNotifications ({ commit, getters }, params = {}) {
  params.rowsPerPage = 0

  return axios.get('/api/notifications', { params })
    .then(response => response.data)
    .then(result => {
      if (result.data) {
        commit('setNotifications', result.data)
        commit('setPagination', Object.assign({}, getters['getPagination'], {
          page: result.current_page,
          rowsPerPage: result.per_page,
          rowsNumber: result.total
        }))
      } else {
        commit('setNotifications', result)
      }
    })
}

export function saveNotification ({ dispatch }, params = {}) {
  return axios.post('/api/notifications/', params)
    .then(() => { dispatch('fetchNotifications') })
}

export function deleteNotification ({ dispatch }, id) {
  return axios.delete(`/api/notifications/${id}`)
    .then(() => { dispatch('fetchNotifications') })
}

export function deleteAllNotification ({ dispatch }) {
  return axios.post(`/api/notifications/clear/user`)
    .then(() => { dispatch('fetchNotifications') })
}
