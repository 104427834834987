import axios from 'axios'

export function fetchEntities ({ commit, getters }, params = {}) {
  delete params.rowsNumber

  return axios.get('/api/entities', { params })
    .then(response => response.data)
    .then(result => {
      commit('setEntities', result.data)
      commit('setPagination', Object.assign({}, getters['getPagination'], {
        page: result.current_page,
        rowsPerPage: result.per_page,
        rowsNumber: result.total
      }))
    })
}

export function fetchMapEntities ({ commit, getters }, params = {}) {
  return axios.get('/api/entities/map', { params })
    .then(result => { commit('setMapEntities', result.data) })
}

export function fetchFormFields ({ commit }) {
  return axios.get('/api/config/forms', { params: { model: 'entity' } })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function fetchFilters ({ commit }) {
  return axios.get('/api/config/filters', { params: { model: 'entity' } })
    .then(response => response.data)
    .then(result => {
      getSegmentOptions({ commit }).then(options => {
        const filters = result
        const segmentFilter = filters.find(filter => filter.id === 'segment_id')
        segmentFilter.options = options.map(option => ({
          label: option.label,
          value: option.id
        }))

        commit('setFilters', filters)
      })
    })
}

export function getEntity ({ commit }, id) {
  return axios.get(`/api/entities/${id}`)
    .then(response => response.data)
    .then(entity => { commit('setCurrentEntity', entity) })
}

export function getEntityCibles ({ commit, getters }, id) {
  // return axios.get(`/api/entities/${id}/groupe-cibles/cibles`, { params: { rowsPerPage: 0 } })
  return axios.get(`/api/groupe-cibles`, { params: { model: 'entity', model_id: id, rowsPerPage: 0 } })
    .then(response => response.data)
    .then(cibles => { commit('setCibles', cibles) })
}

export function saveEntity ({ commit }, params) {
  const { id } = params

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/entities/${id}` : '/api/entities/'
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  })
    .then(response => response.data)
}

export function deleteEntity ({ commit }, id) {
  return axios.delete(`/api/entities/${id}`)
    .then(response => response.data)
}

export function getSegmentOptions ({ commit }) {
  return axios.get('/api/segments')
    .then(result => {
      commit('setSegmentOptions', result.data)
      return result.data
    })
}

export function saveSegment (_, params) {
  const { id } = params

  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/segments/${id}` : '/api/segments/'
  const data = params.id ? params : params

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data
  })
    .then(response => response.data)
}

export function deleteSegment ({ commit }, id) {
  return axios.delete(`/api/segments/${id}`)
    .then(response => response.data)
}
