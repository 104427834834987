import axios from 'axios'

export function fetchCibles ({ commit, getters }, params = {}) {
  return axios.get('/api/groupe-cibles/cibles/related-objects', { params })
    .then(response => response.data)
    .then(result => {
      if (result.data) {
        commit('setCibles', result.data)
        commit('setPagination', Object.assign({}, getters['getPagination'], {
          page: result.current_page,
          rowsPerPage: result.per_page,
          rowsNumber: result.total
        }))
      } else {
        commit('setCibles', result)
      }
    })
}

export function fetchMapCibles ({ commit, getters }, params = {}) {
  return axios.get('/api/groupe-cibles/map', { params })
    .then(result => { commit('setMapCibles', result.data) })
}

export function fetchFormFields ({ commit }, params = {}) {
  params['model'] = 'groupeCible'

  return axios.get('/api/config/forms', { params })
    .then(response => response.data)
    .then(result => { commit('setFormFields', result) })
}

export function fetchFilters ({ commit }) {
  axios.get('/api/config/filters', { params: { model: 'groupeCible' } })
    .then(response => response.data)
    .then(groupeCibleFilters => {
      const filters = [
        { id: 'groupe_cible', type: 'group', items: groupeCibleFilters }
      ]

      axios.get('/api/config/filters', { params: { model: 'cible' } })
        .then(response => response.data)
        .then(cibleFilters => {
          filters.push({ id: 'cible', type: 'group', items: cibleFilters })

          commit('setFilters', filters)
        })
    })
}

export function getCible ({ commit }, id) {
  return axios.get(`/api/groupe-cibles/${id}/cibles/related-objects`)
    .then(response => response.data)
    .then(cible => { commit('setCurrentCible', cible) })
}

export function saveCible ({ commit }, params) {
  const { id, date_validation, ...data } = params
  const apiVerb = id ? 'patch' : 'post'
  const apiRoute = id ? `/api/groupe-cibles/${id}` : '/api/groupe-cibles/'

  return axios.request({
    method: apiVerb,
    url: apiRoute,
    data: data
  })
    .then(response => response.data)
}

export function deleteCible ({ commit }, id) {
  return axios.delete(`/api/groupe-cibles/${id}`)
    .then(response => response.data)
}
