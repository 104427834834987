export default {
  formFields: null,
  filters: null,
  pagination: {
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 0,
    rowsNumber: 0
  },
  processes: [],
  currentProcess: null
}
