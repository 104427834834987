import Vue from 'vue'
import Vuex from 'vuex'

const camelCase = require('voca/camel_case')

const requireModule = require.context('.', true, /index.js$/)
const modules = {}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return

  const moduleName = camelCase(fileName.replace(/(\.\/|\/index.js)/g, ''))
  modules[moduleName] = requireModule(fileName).default
})

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({ modules })

  return Store
}
