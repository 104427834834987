import regions from './regions'

const types = {
  'AFFAIRES_COURANTES': 'Affaires courantes',
  'AGREMENT_DE_CANDIDAT': 'Agrément de candidat',
  'AIDES': 'Aides',
  'AUDIT_COMPTABLE': 'Audit comptable',
  'CANDIDATURE': 'Candidature',
  'CHANTIERS_ADMINISTRATION_DES_AGENCES': 'Chantiers d\'administration des agences',
  'CONTENTIEUX': 'Contentieux',
  'COURTAGE': 'Courtage',
  'DEPART_AGENT': 'Départ agent',
  'DESTINATION_TRANSMISSION': 'Destination Transmission',
  'DIVERS': 'Divers',
  'DOSSIERS_AGENCES': 'Dossiers Agences',
  'EURL_GI': 'EURL GI',
  'GI_COLLABORATEURS': 'GI Collaborateurs',
  'GI_DEMENAGEMENT': 'GI Déménagement',
  'GI_DIVERS': 'GI Divers',
  'INDEMNITES_COMPENSATRICES': 'Indemnités compensatrices',
  'PROTOCOLE_ACCES': 'Protocole d\'accès',
  'REORGANISATION_AGENCE': 'Réorganisation agence',
  'SIGNALETIQUE': 'Signalétique',
  'SUIVI_MANDAT_FERME': 'Suivi mandat ferme',
  'SUIVI_PERIODE_PROBATOIRE_12_MOIS': 'Suivi période probatoire 12 mois',
  'SUIVI_PERIODE_PROBATOIRE_18_MOIS': 'Suivi période probatoire 18 mois',
  'SUIVI_PERIODE_PROBATOIRE_6_MOIS': 'Suivi période probatoire 6 mois',
  'PER': 'PER'
}

const statuses = {
  'BROUILLON': 'Brouillon',
  'A_PLANIFIER': 'À planifier',
  'AFFECTE': 'Affecté',
  'TRAITE': 'Traité'
}

export default {
  sujets: 'Sujets',
  sujet: 'Sujet',
  sujets_list: 'Sujets',
  assign_sujet: 'Assigner un sujet',
  add_link: 'Ajouter un lien',
  assign_object: 'Associer un objet',
  assign_cible: 'Ajouter une cible',
  add_sujet: 'Créer un sujet',
  edit_sujet: 'Modifier le sujet',
  delete_sujet: 'Supprimer ce sujet',
  delete_sujet_confirm: 'Êtes-vous sûr de vouloir supprimer ce sujet ?',
  traiter_sujet: 'Traiter',
  delete_linked_link: 'Supprimer le lien',
  delete_link_confirm: 'Êtes-vous sûr de vouloir supprimer ce lien ?',
  delete_link_success: 'Lien supprimé',

  fields: {
    label: { label: 'Titre du sujet' },
    type_id: { label: 'Type de sujet', options: types },
    description: { label: 'Description' },
    compte_rendu: { label: 'Compte-rendu' },
    date_limite: { label: 'Limite d\'affectation' },
    date_instance: { label: 'Date' },
    statut: { label: 'État', options: statuses },
    impact_per: { label: 'Impact PER' },
    visibilite: { label: 'Diffusion restreinte' },
    region_id: { label: 'Région', options: regions }
  },
  filters: {
    type_id: { label: 'Type', options: types },
    date_instance: { label: 'Date d\'instance' },
    statut: { label: 'État', options: statuses },
    region_id: { label: 'Région', options: regions }
  },
  save_success: 'Sujet sauvegardé',
  delete_success: 'Sujet supprimé',
  related_objects: 'Objet(s) lié(s)',
  related_links: 'Lien(s)',
  related_cibles: 'Cible(s) liée(s)'
}
