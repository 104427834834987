export function getEvenements (state) {
  return state.evenements
}

export function getEvenementsFor (state) {
  return (modelType, modelId) => { return state.evenements }
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrent (state) {
  return state.currentEvenement
}
