export default {
  comptage_reseau: null,
  cibles_EN_COURS: null,
  cibles_TERMINE: null,
  cibles_ABANDONNE: null,
  sous_cibles_EN_COURS: null,
  sous_cibles_TERMINE: null,
  sous_cibles_ABANDONNE: null,
  comptage: null,
  filters: null
}
