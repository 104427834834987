export function getPtfConfies (state) {
  return state.ptfsConfies
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrentPtfConfie (state) {
  return state.currentPtfConfie
}
