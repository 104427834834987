<template>
  <q-timeline class="col" :color="color" :key="timelineKey">
    <q-timeline-entry v-for="entry in timelineEntries" v-bind:key="entry.id" v-bind="entry">
      <slot :item="entry.item" v-if="entry.item" />
    </q-timeline-entry>
  </q-timeline>
</template>

<script>
import { date } from 'quasar'
const { getDateDiff } = date

export default {
  name: 'base-timeline',
  props: {
    items: { type: Array, required: true },
    date_field: { type: String, default: 'date' },
    color: { type: String }
  },

  data () {
    return {
      timelineKey: 0
    }
  },

  computed: {
    timelineEntries () {
      const sortedItems = this.items.slice().sort((a, b) => {
        const dateA = new Date(a[this.date_field])
        const dateB = new Date(b[this.date_field])
        return getDateDiff(dateA, dateB, 'seconds') < 0 ? 1 : -1
      })

      const entries = []
      let curYear, curMonth

      sortedItems.forEach(item => {
        const itemDate = new Date(item[this.date_field])
        const itemYear = this.$formatDate(itemDate, 'YYYY')
        const itemMonth = this.$formatDate(itemDate, 'MMMM')

        if (itemYear !== curYear) {
          curYear = itemYear
          entries.push({ title: curYear })
        }
        if (itemMonth !== curMonth) {
          curMonth = itemMonth
          entries.push({ subtitle: curMonth })
        }

        entries.push({ item })
      })

      return entries
    }
  },

  watch: {
    items (newVal, oldVal) {
      this.timelineKey++
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
