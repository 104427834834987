export default {
  props: {
    'id': { type: String },
    'type': { type: String },
    'label': { type: String },
    'options': { type: Array, default: () => ([]) },
    'required': { type: Boolean },
    'readonly': { type: Boolean },
    'color': { type: String },
    'value': { type: [String, Number, Date, Boolean, Object], default: () => null },
    'default': { type: [String, Number, Date, Boolean], default: () => null },
    'dense': { type: Boolean, default: false },
    'rules': { type: Array, default: () => ([]) }
  },

  data () {
    return {
      baseRules: []
    }
  },

  computed: {
    input () {
      return this.$refs['input']
    },

    isRequired () {
      return this.required && (v => !!v || this.$t('form.required_field'))
    },

    hasError () {
      return this.input.hasError
    },

    error () {
      return this.input.error
    }
  },

  mounted () {
    this.baseRules.push(...this.rules)
    if (this.isRequired) {
      this.baseRules.push(v => !!v || this.$t('form.required_field'))
    }
  },
  watch: {
    default: {
      handler (defaultValue) {
        if (this.value === null && defaultValue !== null) this.$emit('input', defaultValue)
      },
      immediate: true
    }
  },

  methods: {
    validate () {
      this.input.validate()
    },

    resetValidation () {
      // this.input.resetValidation()
    }
  }
}
