export function getCandidats (state) {
  return state.candidats
}

export function getSessionStage (state) {
  console.log('ato hoa')
  return state.sessionStage
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrentCandidat (state) {
  return state.currentCandidat
}
