export default {
  formFields: null,
  filters: null,
  pagination: {
    sortBy: 'date_cible',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 0
  },
  tasks: [],
  currentTask: null
}
