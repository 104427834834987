import { Dialog } from 'quasar'

export default async ({ app, router, Vue }) => {
  Vue.prototype.$confirm = (message, submit_label, color_ok = 'negative') => {
    return Dialog.create({
      persistent: true,
      message: message,
      ok: {
        label: submit_label,
        unelevated: true,
        flat: true,
        color: color_ok
      },
      cancel: {
        flat: true,
        color: 'primary'
      }
    })
  }
}
