export function getSujets (state) {
  return state.sujets
}

export function getFormFields (state) {
  return state.formFields
}

export function getFilters (state) {
  return state.filters
}

export function getPagination (state) {
  return state.pagination
}

export function getCurrentSujet (state) {
  return state.currentSujet
}
