const types = {
  SUJET_AFFECTE: 'Sujet affecté',
  SUJET_REJETE: 'Sujet rejeté',
  SUJET_TRAITE: 'Sujet traité',
  SUJET_A_AFFECTER: 'Sujet à affecter',
  CIBLE_PROPOSE_RPPR: 'Cible proposée RPPR',
  CIBLE_PROPOSE_SIEGE: 'Cible proposée siège',
  CIBLE_REJETE: 'Cible rejetée',
  CIBLE_VALIDE: 'Cible validée',
  SOUS_CIBLE_TERMINE: 'Sous-cible terminée',
  SOUS_CIBLE_ABANDONNE: 'Sous-cible abandonnée',
  NEW_TASK_COMMENT: 'Tâche - Nouveau commentaire',
  TACHE_A_VALIDER: 'Tâche - Nouvelle tâche à valider',
  INSTANCE_CLOTURE: 'Instance clôturée',
  DIVERGENCES_IMPORTED: 'Divergences importées',
  PERIODE_PROBATOIRE_RELANCE: 'Suivi de période probatoire'
}

export default {
  notifications: 'Notifications',
  notification: 'Notification',
  delete_notification: 'Supprimer',
  delete_all_notifications: 'Tout supprimer',
  delete_notification_confirm: 'Êtes-vous sûr de vouloir supprimer cette notification ?',
  delete_all_notifications_confirm: 'Êtes-vous sûr de vouloir supprimer toutes les notifications ?',
  delete_success: 'Notification supprimée',
  types
}
